import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AlertController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  publishReplay,
  refCount,
  switchMap,
  tap,
} from 'rxjs/operators';

import { Config } from '../config';
import {
  EmailVerificationModel,
  ForgotPasswordModel,
  LoginModel,
  RegisterUserModel,
  ResendVerificationCodeModel,
  ResetPasswordModel,
  TokenResponseModel,
  ValidateModel,
} from '../models/auth.model';
import { PageModel } from '../models/page.model';
import { UserModel } from '../models/user.model';

import { ErrorHandlerService } from './error-handler.service';
import { HttpService } from './http.service';
import { TokenStoreService } from './token-store.service';

@Injectable()
export class AuthService extends HttpService {
  private refreshing: Observable<TokenResponseModel>;
  private alert;

  constructor(
    http: HttpClient,
    private router: Router,
    private storage: Storage,
    private handler: HttpBackend,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private tokenStoreService: TokenStoreService,
    private errorHandlerService: ErrorHandlerService,
  ) {
    super(http);
  }

  get loggedIn() {
    return this.tokenStoreService.loggedIn;
  }

  get onLoginChange() {
    return this.tokenStoreService.onLoginChange;
  }

  get onTokenChange() {
    return this.tokenStoreService.onTokenChange;
  }

  get token() {
    return this.tokenStoreService.token;
  }

  forgot(request: ForgotPasswordModel) {
    const http = new HttpClient(this.handler);
    return http.post(`${Config.apiUrl}/auth/forgot`, request);
  }

  login(credentials: LoginModel) {
    const http = new HttpClient(this.handler);
    return http
      .post<TokenResponseModel>(`${Config.apiUrl}/auth/login`, credentials)
      .pipe(switchMap((token) => this.tokenStoreService.updateToken(token)));
  }

  socialLogin(
    provider: string,
    access_token: {
      access_token: string;
      first_name?: string;
      last_name?: string;
      email?: string;
    },
  ) {
    const http = new HttpClient(this.handler);
    return http
      .post<TokenResponseModel>(
        `${Config.apiUrl}/auth/social/${provider}`,
        access_token,
      )
      .pipe(switchMap((token) => this.tokenStoreService.updateToken(token)));
  }

  logout() {
    return this.token.pipe(
      switchMap((token) => {
        if (token) {
          return this.http.post(`${Config.apiUrl}/logout`, null).pipe(
            catchError(() => of()),
            switchMap(() => this.tokenStoreService.updateToken(null)),
            map(() => true),
          );
        }

        return of(true);
      }),
    );
  }

  register(request: RegisterUserModel) {
    return this.http.post(`${Config.apiUrl}/auth/register`, request);
  }

  refresh(currentToken: string): Observable<TokenResponseModel> {
    if (!currentToken) {
      const error = new Error('unauthorized');
      return throwError(error);
    }

    if (!this.refreshing) {
      this.refreshing = this.http
        .post<TokenResponseModel>(`${Config.apiUrl}/auth/refresh`, null, {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        })
        .pipe(
          switchMap((newToken) =>
            this.tokenStoreService.updateToken(newToken).then(() => newToken),
          ),
          tap(() => {
            this.refreshing = null;
          }),
          publishReplay(1),
          refCount(),
        );
    }

    return this.refreshing;
  }

  reset(request: ResetPasswordModel) {
    const http = new HttpClient(this.handler);
    return http.post<TokenResponseModel>(
      `${Config.apiUrl}/auth/reset-password`,
      request,
    );
  }

  emailVerification(request: EmailVerificationModel) {
    const http = new HttpClient(this.handler);
    return http
      .post(`${Config.apiUrl}/auth/verify`, request)
      .pipe(
        switchMap((token: any) => this.tokenStoreService.updateToken(token)),
      );
  }

  resendVerificationCode(request: ResendVerificationCodeModel) {
    const http = new HttpClient(this.handler);
    return http.post(`${Config.apiUrl}/auth/verify/resend`, request);
  }

  updateProfile(request: UserModel) {
    return this.http.post<UserModel>(`${Config.apiUrl}/user`, request);
  }

  validate(request: ValidateModel) {
    return this.http
      .post<TokenResponseModel>(`${Config.apiUrl}/auth/validate`, request)
      .pipe(switchMap((token) => this.tokenStoreService.updateToken(token)));
  }

  refreshToken(request: string) {
    const userName = { user_name: request };
    if (!this.refreshing) {
      this.refreshing = this.http
        .post<TokenResponseModel>(`${Config.apiUrl}/auth/login`, userName)
        .pipe(
          switchMap((newToken) =>
            this.tokenStoreService.updateToken(newToken).then(() => newToken),
          ),
          tap(() => {
            this.refreshing = null;
          }),
          publishReplay(1),
          refCount(),
        );
    }
    return this.refreshing;
  }

  showLogin() {
    return this.translate
      .get([
        'Login.Title',
        'Login.Form.Email',
        'Login.Form.Password',
        'Buttons.Login',
        'Buttons.Cancel',
      ])
      .pipe(
        switchMap(
          (messages) =>
            new Observable<boolean>((observer) => {
              this.storage
                .get('login_email')
                .catch(() => null)
                .then((email) => {
                  this.loginAlert(messages, observer, email);
                });
              return () => {
                this.alert.dismiss();
              };
            }),
        ),
      );
  }

  async loginAlert(messages, observer, email) {
    this.alert = await this.alertCtrl.create({
      header: messages['Login.Title'],
      backdropDismiss: true,
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: messages['Login.Form.Email'],
          value: email || null,
        },
        {
          name: 'password',
          type: 'password',
          placeholder: messages['Login.Form.Password'],
        },
      ],
      buttons: [
        {
          text: messages['Buttons.Cancel'],
          role: 'cancel',
          handler: () => {
            observer.next(false);
            observer.complete();

            return false;
          },
        },
        {
          text: messages['Buttons.Login'],
          handler: (data) => {
            this.loadingCtrl
              .create({
                message: this.translate.instant(marker('Refresh.Loading')),
              })
              .then((loader) => {
                loader.present();
              });
            this.login(data)
              .pipe(finalize(() => this.loadingCtrl.dismiss()))
              .subscribe({
                next: () => {
                  this.storage.set('login_email', data.email);

                  observer.next(true);
                  observer.complete();
                },
                error: (error: unknown) =>
                  this.errorHandlerService.handle(error),
              });

            return false;
          },
        },
      ],
    });
    this.alert.present();
  }

  getPageDetail(type: any) {
    return this.http.get<PageModel>(this.buildUrl('page'), {
      params: this.serialize({ type }),
    });
  }

  getPages() {
    return this.http.get<Array<PageModel>>(this.buildUrl('pages'));
  }
}
