import { Options } from 'ng5-slider';

import { ChatRequestModel } from './chat.model';
import { CurrencyModel } from './housing.model';

export interface BaseUserModel {
  id?: number;
  name: string;
  email: string;
  image_url?: string | null;
}

export interface UserModel {
  id: number;
  profile: ProfileModel;
  email: string;
  locale: string;
  is_blocked: boolean;
  email_verified: boolean;
  no_of_connections?: any;
  languages: Array<string>;
  interests: Array<InterestsModel>;
  futureStudies: Array<string>;
  nationalities: Array<Nationality>;
  chat_request?: ChatRequestModel;
  is_match_visited: boolean;
  is_housing_visited: boolean;
  is_events_visited: boolean;
  is_products_visited: boolean;
  profile_completed: boolean;
  total_unread_messages: number;
  current_institution: InstitutionModel;
  future_institutes: Array<InstitutionModel>;
}

export interface InstitutionModel {
  id: number;
  name: string;
  city: CityModel;
  university_id: number;
  is_arrived: boolean;
}

interface Currency {
  id: number;
  symbol: string;
  code: string;
  name: string;
}

export interface Nationality {
  id: number;
  title: string;
  code_iso2: string;
  nationality: string;
  latitude: string;
  longitude: string;
  currency: Currency;
}

export interface CityModel {
  id: number;
  name: string;
  latitude: string;
  longitude: string;
  nationality: Nationality;
  is_arrived?: number;
  place_id: string;
}

export interface ProfileModel {
  first_name: string;
  last_name: string;
  full_name: string;
  display_name: string;
  images: Image;
  phone: string | null;
  gender: string;
  age: number;
  max_radius: number;
  current_location: Location;
  current_city: CityModel;
  longitude: number;
  latitude: number;
  about_me: string;
  currentStudy: { [key: string]: string };
  current_study_location: Location;
  is_at_future_study_location: boolean;
  is_match_visited: boolean;
  plan_to_study_abroad: boolean;
  is_housing_visited: boolean;
  is_events_visited: boolean;
  is_products_visited: boolean;
  future_study_program: string | null;
  date_of_birth: string;
  future_study_to: string;
  future_study_from: string;
  current_study_to: string;
  current_study_from: string;
  found_as_local: boolean;
  is_current_study_city: string;
}

interface ImageSize {
  width: number;
  height: number;
  url: string;
}

interface ImageConversion {
  thumb: { [key: string]: string };
  large: { [key: string]: string };
  xlarge: { [key: string]: string };
  full: { [key: string]: string };
}

export interface Image {
  id?: number;
  file_name?: string;
  mime_type?: string;
  size?: number;
  url?: string;
  responsive?: { [key: string]: string };
  conversions?: ImageConversion;
  expires_at?: number;
  created_at?: string;
  updated_at?: string;
}

interface Currency {
  id: number;
  symbol: string;
  code: string;
  name: string;
}

export interface Location {
  id: number;
  title: string;
  code_iso2: string;
  nationality: Nationality;
  latitude: string;
  longitude: string;
  currency: Currency;
}

export interface CitiesModel {
  id: number;
  name: string;
  latitude: string;
  longitude: string;
  currency: CurrencyModel;
  nationality: CurrencyModel;
}

export interface SelectedCityModel {
  id: number;
  name: string;
  latitude?: number;
  longitude?: number;
  currency?: CurrencyModel;
  nationality?: CurrencyModel;
  nationality_id?: number;
}

export interface LanguagesModel {
  id: number;
  code: string;
  title: string;
}

export interface InterestsModel {
  id: number;
  title: string;
}

export interface SubInterestsModel {
  id: number;
  title: string;
}

export interface LanguageResponseModel {
  message: string;
}

export const SliderOptions: Options = {
  showTicksValues: true,
  stepsArray: [
    { value: 0 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
    { value: 30 },
    { value: 35 },
    { value: 40 },
    { value: 45 },
    { value: 50 },
  ],
};

export interface ReportUserPaylaod {
  reported_reason_id: number;
  message: string;
}

export interface UserFiltersModel {
  min_age: number;
  max_age: number;
  gender: CheckboxModel[];
  interests: Array<number>;
  nationalities: Array<number>;
  future_studies: string;
  preferred_universities: string;
  cities?: Array<string>;
  future_study_program?: CheckboxModel[];
  status: CheckboxModel[];
}

export interface CheckboxModel {
  title: string;
  value: string;
  checked: boolean;
}

export interface ReportReasonsResponseModel {
  id: number;
  text: string;
}

export interface MatchesModel {
  id: number;
  email: string;
  email_verified: boolean;
  locale: string;
  is_blocked: boolean;
  profile: MatchProfileModel;
  chat_request?: ChatRequestModel;
}

export interface MatchProfileModel {
  first_name: string;
  last_name: string;
  images: any;
  gender: string;
  age: number;
  max_radius: number;
  current_location: string;
  longitude: number;
  latitude: number;
  about_me: string;
  current_study: string;
  current_study_location: string;
  is_at_future_study_location: string;
  future_study_locations: Array<any>;
  preferred_universities: Array<string>;
  date_of_birth: string;
  future_study_to: string;
  future_study_from: string;
}

export interface FutureStudiesModel {
  id: number;
  title: string;
  program_level: {
    id: number;
    title: string;
  };
}

export interface UserProfilePayloadModel {
  id: number;
  gender: string;
  about_me: string;
  last_name: string;
  first_name: string;
  is_current_study_city: boolean;
  current_city_id?: any;
  current_city_code_iso2?: string;
  current_study_id: number;
  date_of_birth: string;
  images: Array<string>;
  future_study_to: string;
  code_iso2: Array<string>;
  interests: Array<number>;
  languages: Array<number>;
  future_study_from: string;
  current_institution: string;
  current_location: Array<number>;
  nationalities: Array<number>;
  future_studies: Array<string>;
  current_study_location: Array<number>;
  current_study_city_id: number;
  is_at_future_study_location: boolean;
  preferred_universities: Array<string>;
  future_study_locations: Array<string>;
  future_study_program: string;
  plan_to_study_abroad: boolean;
  current_study_from: string;
  current_study_to: string;
  found_as_local: boolean;
}

export interface UniversityModel {
  id: number;
  name: string;
  nationality_id: number;
}

export const MatchingUserKeys = [
  'gender',
  'max_age',
  'min_age',
  'interests',
  'nationalities',
  'future_studies',
  'preferred_universities',
  'is_at_future_study_location',
];
