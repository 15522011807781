import { Component, Input } from '@angular/core';

import { PostResponseModel } from '../../models/post.model';
import { PhotoViewerService } from '../../services/photo-viewer.service';

@Component({
  selector: 'studinty-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
})
export class PostCardComponent {
  @Input() itemDetail: PostResponseModel;
  @Input() image: any;
  @Input() noImage: boolean;
  @Input() showImage: boolean = true;

  constructor(private photoViewer: PhotoViewerService) {}

  async previewImage(url: string) {
    if (this.showImage) {
      await this.photoViewer.show(url);
    }
  }
}
