export const asc = 'asc';
export const perPage = 25;
export const desc = 'desc';
export const maxImages = 4;
export const apple = 'apple';
export const sortColumn = 'id';
export const google = 'google';
export const defaultUnit = 'km';
export const direction = 'desc';
export const defaultRadiusHousing = 0;
export const defaultRadiusEvents = 0; // default radius as per initial requirement
export const facebook = 'facebook';
export const phonePattern = '^[0-9]*$';
export const defaultRadiusMarketPlace = 0; // default radius as per initial requirement
export const onlineStatusInterval = 180000; // setting 5 minute interval time
export const studintyAccessToken = 'studinty_access_token';
export const Timeline = 'timeline';
export const HousingAd = 'housing-ad';
export const EventAd = 'event-ad';
export const ProductAd = 'marketplace-offer-ad';
export const TimeLinePost = 'timeline-post';
export const Post = 'post';
export const NationalityPerPage = 900;
export const interestsPerPage = 300;
export const currentStudiesPerPage = 300;
export const universitiesPerPage = 900;
export const languagesPerPage = 300;
export const SPECIAL_CHARACTERS_FORMAT =
  /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
export const chatCharLimit = 300;

// const for profile page
export const language = 'language';
export const nationality = 'nationality';
export const currentLocation = 'currentLocation';
export const currentUniversity = 'currentUniversity';
export const futureStudyLocation = 'futureStudyLocation';
export const futureStudyCity = 'futureStudyCity';
export const currentStudyLocation = 'currentStudyLocation';
export const futureStudyUniversity = 'futureStudyUniversity';
export const interest = 'interest';
export const currStudies = 'currentStudies';
export const futreStudies = 'futureStudies';
export const other = 'Other';

// ends here

export const RemoveAdReasons = {
  AdSold: 'sold',
  AdNotSold: 'not_sold',
  Nothing: 'nothing',
};
export const PostAd = 'post';
export const mapThemeJSON: any = {
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#cccccc',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'of',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#919191',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ],
};
