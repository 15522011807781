import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { UserModel } from '../../models/user.model';
import { chatCharLimit } from '../../constants/general.constant';

@Component({
  selector: 'studinty-send-request-modal',
  templateUrl: './send-request-modal.component.html',
  styleUrls: ['./send-request-modal.component.scss'],
})
export class SendRequestModalComponent implements OnInit {
  form: UntypedFormGroup;
  protected readonly chatCharLimit = chatCharLimit;
  @Input() user: UserModel;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      requestMessage: new UntypedFormControl('Hi', [
        Validators.required,
        Validators.maxLength(chatCharLimit),
        Validators.minLength(2),
      ]),
    });
  }

  sendRequest() {
    this.modalController.dismiss(this.form.value.requestMessage);
  }

  dismiss(data) {
    this.modalController.dismiss(data);
  }
}
