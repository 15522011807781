import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IonicSelectableModule } from 'ionic-selectable';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { ScrollbarThemeDirective } from '../directives/scrollbar-theme.directive';
import { PipesModule } from '../pipes/pipes.module';

import { EventAdCardComponent } from './event-ad-card/event-ad-card.component';
import { EventItemComponent } from './event-item/event-item.component';
import { FavoriteEventsComponent } from './favorite-events/favorite-events.component';
import { FavoriteHousingComponent } from './favorite-housing/favorite-housing.component';
import { FavoriteMarketPlaceComponent } from './favorite-market-place/favorite-market-place.component';
import { FilterComponent } from './filters/filter.component';
import { HousingAdCardComponent } from './housing-ad-card/housing-ad-card.component';
import { HousingItemComponent } from './housing-item/housing-item.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { MapComponent } from './map/map.component';
import { MarketplaceItemComponent } from './marketplace-item/marketplace-item.component';
import { MarketplaceListItemComponent } from './marketplace-list-item/marketplace-list-item.component';
import { MatchingFilterComponent } from './matching-filter/matching-filter.component';
import { NewRequestModalComponent } from './new-request-modal/new-request-modal.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PostComponent } from './post/post.component';
import { PostCardComponent } from './post-card/post-card.component';
import { ProductAdCardComponent } from './product-ad-card/product-ad-card.component';
import { ProfileDetailComponent } from './profile-detail/profile-detail.component';
import { ReportAdComponent } from './report-ad/report-ad.component';
import { SendRequestModalComponent } from './send-request-modal/send-request-modal.component';
import { ValidationMessagesComponent } from './validation-messages/validation-messages.component';

@NgModule({
  imports: [
    IonicModule,
    FormsModule,
    RouterModule,
    CommonModule,
    TranslateModule,
    GooglePlaceModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    IonicSelectableModule,
    PipesModule,
  ],
  declarations: [
    MapComponent,
    PostComponent,
    FilterComponent,
    ReportAdComponent,
    NotFoundComponent,
    ProfileDetailComponent,
    FavoriteEventsComponent,
    FavoriteHousingComponent,
    ValidationMessagesComponent,
    FavoriteMarketPlaceComponent,
    MatchingFilterComponent,
    ScrollbarThemeDirective,
    SendRequestModalComponent,
    HousingAdCardComponent,
    ProductAdCardComponent,
    EventAdCardComponent,
    HousingItemComponent,
    MarketplaceItemComponent,
    EventItemComponent,
    PostCardComponent,
    MarketplaceListItemComponent,
    NewRequestModalComponent,
    ImagePreviewComponent,
  ],
  exports: [
    MapComponent,
    PostComponent,
    FilterComponent,
    NotFoundComponent,
    IonicSelectableModule,
    ProfileDetailComponent,
    FavoriteEventsComponent,
    FavoriteHousingComponent,
    ValidationMessagesComponent,
    FavoriteMarketPlaceComponent,
    MatchingFilterComponent,
    ScrollbarThemeDirective,
    SendRequestModalComponent,
    HousingAdCardComponent,
    ProductAdCardComponent,
    EventAdCardComponent,
    HousingItemComponent,
    MarketplaceItemComponent,
    EventItemComponent,
    PostCardComponent,
    MarketplaceListItemComponent,
    NewRequestModalComponent,
  ],
})
export class ComponentsModule {}
