import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Config } from '../config';
import {
  NotificationsModel,
  SettingsModel,
} from '../models/notifications.model';
import { PaginatedResponse } from '../models/paginated-response.model';

import { HttpService } from './http.service';

@Injectable()
export class NotificationService extends HttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  getNotifications(
    params?: any,
  ): Observable<PaginatedResponse<NotificationsModel>> {
    return this.http.get<PaginatedResponse<NotificationsModel>>(
      `${Config.apiUrl}/notification`,
      {
        params: this.serialize(params),
      },
    );
  }

  getSettings(): Observable<SettingsModel> {
    return this.http.get<SettingsModel>(
      `${Config.apiUrl}/settings/notification`,
    );
  }

  updateSettings(body) {
    return this.http.post(this.buildUrl(`settings/toggle-notification`), body);
  }

  markAllAsRead() {
    return this.http.post(this.buildUrl('notification/mark-all-as-read'), {});
  }

  getNotificationCounter() {
    return this.http.get(this.buildUrl('notification/unread/count'));
  }

  clearAllNotifications() {
    return this.http.delete(this.buildUrl('notification/destroy'));
  }
}
