import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  interestsPerPage,
  NationalityPerPage,
} from '../../constants/general.constant';
import { PaginatedResponse } from '../../models/paginated-response.model';
import {
  CheckboxModel,
  InstitutionModel,
  InterestsModel,
  SelectedCityModel,
  UserFiltersModel,
} from '../../models/user.model';
import { BasePage } from '../../pages/base.page';
import { AuthService } from '../../services/auth.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'studinty-matching-filter',
  templateUrl: './matching-filter.component.html',
  styleUrls: ['./matching-filter.component.scss'],
})
export class MatchingFilterComponent extends BasePage implements OnDestroy {
  @Input() isFutureStudyCity: boolean;
  @Input() selectedCity: InstitutionModel;
  @ViewChild('selectableRef') selectableRef;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  form: UntypedFormGroup;
  isLoaded = false;
  minAge: UntypedFormControl;
  maxAge: UntypedFormControl;
  filters: UserFiltersModel;
  interests: PaginatedResponse<InterestsModel>;
  userStudyPrograms: CheckboxModel[] = [
    {
      title: 'Profile.Form.StudyExchange',
      value: 'study',
      checked: true,
    },
    {
      title: 'Profile.Form.Internship',
      value: 'internship',
      checked: true,
    },
    {
      title: 'Profile.Form.LanguageCourse',
      value: 'language',
      checked: true,
    },
  ];

  genders: CheckboxModel[] = [
    {
      title: marker('Filters.Form.Male'),
      value: 'male',
      checked: true,
    },
    {
      title: marker('Filters.Form.Female'),
      value: 'female',
      checked: true,
    },
    {
      title: marker('Filters.Form.Other'),
      value: 'other',
      checked: true,
    },
  ];

  status: CheckboxModel[] = [
    {
      title: marker('Filters.Arrived'),
      value: 'arrived',
      checked: true,
    },
    {
      title: marker('Filters.NotArrived'),
      value: 'not_arrived',
      checked: true,
    },
  ];
  preferredUniversityToggle: boolean;
  futureStudiesToggles: boolean = false;

  constructor(
    loadingCtrl: LoadingController,
    authService: AuthService,
    toastController: ToastController,
    userService: UserService,
    platform: Platform,
    protected translate: TranslateService,
    private errorHandler: ErrorHandlerService,
    protected alertController: AlertController,
    public modalController: ModalController,
    public modalCtrl: ModalController,
  ) {
    super(
      platform,
      userService,
      authService,
      translate,
      loadingCtrl,
      toastController,
    );
  }

  ionViewWillEnter() {
    this.getFilters();
  }

  initializeForm(): void {
    // age range custom controls for custom validations
    this.minAge = new UntypedFormControl(
      this.filters?.min_age || null,
      Validators.compose([
        Validators.min(1),
        Validators.max(100),
        (control: AbstractControl) => {
          if (typeof this.maxAge !== 'undefined' && this.maxAge.value) {
            if (this.maxAge.valid && control.value > this.maxAge.value) {
              return { smaller: true };
            }
          }
          if (this.maxAge) {
            this.maxAge.setErrors(null);
          }
          return null;
        },
      ]),
    );

    this.maxAge = new UntypedFormControl(
      this.filters?.max_age || null,
      Validators.compose([
        Validators.min(1),
        Validators.max(100),
        (control: AbstractControl) => {
          if (
            control.value &&
            this.minAge.valid &&
            control.value < this.minAge.value
          ) {
            return { greater: true };
          }
          if (this.minAge) {
            this.minAge.setErrors(null);
          }
          return null;
        },
      ]),
    );

    this.preferredUniversityToggle =
      this.filters?.preferred_universities === 'yes';
    this.futureStudiesToggles = this.filters?.future_studies === 'yes';

    this.form = new UntypedFormGroup({
      gender: new UntypedFormControl(this.filters?.gender || null),
      min_age: this.minAge,
      max_age: this.maxAge,
      // is_at_future_study_location: new UntypedFormControl(
      //   this.filters?.is_at_future_study_location === true
      //     ? true
      //     : this.filters?.is_at_future_study_location === false
      //     ? false
      //     : null,
      // ),
      preferred_universities: new UntypedFormControl(
        this.preferredUniversityToggle,
      ),
      future_studies: new UntypedFormControl(this.futureStudiesToggles),
      nationalities: new UntypedFormControl(
        this.filters?.nationalities || null,
      ),
      interests: new UntypedFormControl(this.filters?.interests || null),
      future_study_program: new UntypedFormControl(
        this.filters?.future_study_program || null,
      ),
      status: new UntypedFormControl(this.filters?.status || null),
    });
  }

  /**
   * Get Filters set by user or user profile
   */
  getFilters() {
    this.showLoader().then(() => {
      forkJoin([
        this.userService.getFilters(),
        this.userService.getInterests({
          per_page: interestsPerPage,
          direction: 'asc',
          sort: 'title',
        }),
        this.userService.getUpdatedUser(),
        this.userService.getNationalities({
          per_page: NationalityPerPage,
          sort: 'nationality',
          direction: 'asc',
        }),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (filters: any) => {
            this.hideLoader();
            this.filters = filters[0].profile;
            if (this.filters?.future_study_program) {
              this.userStudyPrograms = this.filters.future_study_program;
            }
            if (this.filters?.gender) {
              this.genders = this.filters.gender;
            }
            if (this.filters?.status) {
              this.status = this.filters.status;
            }
            this.interests = filters[1];
            this.user = filters[2];
            this.nationalities = filters[3];
            this.initializeForm();
            this.isLoaded = true;
          },
          error: (error: unknown) => {
            this.hideLoader();
            this.errorHandler.handle(error);
          },
        });
    });
  }

  /**
   * Form submission
   */
  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.errorHandler.formValidationError();
      return;
    }
    this.form.patchValue({
      future_study_program: this.userStudyPrograms,
      gender: this.genders,
      status: this.status,
      preferred_universities:
        this.preferredUniversityToggle === true ? 'yes' : 'no',
      future_studies: this.futureStudiesToggles === true ? 'yes' : 'no',
    });
    this.showLoader().then(() => {
      this.userService
        .setFilters(this.form.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            this.hideLoader();
            this.modalCtrl.dismiss({ refresh: true });
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    });
  }

  clearFilters() {
    this.form.reset();
    // reset genders and user study programs
    this.genders = null;
    this.userStudyPrograms = null;
    this.futureStudiesToggles = false;
    this.preferredUniversityToggle = false;
    this.status = null;
    this.submit();
  }

  dismiss() {
    this.modalCtrl.dismiss({ refresh: false });
  }

  selectClosed() {
    this.selectableRef._searchText = '';
  }

  searchThroughInterests(
    event: { component: IonicSelectableComponent; text: string },
    infinite = false,
  ) {
    if (event.text.length === 1) {
      return;
    }
    this.actionHandler(
      event,
      this.interests,
      infinite,
      this.userService.getInterests.bind(this.userService),
    );
  }

  actionHandler(
    event: { component: IonicSelectableComponent; text: string },
    record: PaginatedResponse<any>,
    isInfinite: boolean,
    fn: CallableFunction,
  ) {
    if (isInfinite) {
      this.infinitescroll(event, record, fn);
    } else {
      this.searchAction(event, record, fn);
    }
  }

  searchAction(
    event: { component: IonicSelectableComponent; text: string },
    record: PaginatedResponse<any>,
    fn: CallableFunction,
  ) {
    const text = event.text.trim().toLowerCase();

    event.component.startSearch();

    if (!text) {
      event.component.items = record.data;

      // Enable and start infinite scroll from the beginning.
      record.meta.current_page++;
      event.component.endSearch();
      event.component.enableInfiniteScroll();
      return;
    }

    const payload: any = {
      search: text,
      key: ['title'],
      page: '',
      sort: 'title',
      per_page: interestsPerPage,
      direction: 'asc',
    };

    fn(payload).subscribe((ports) => {
      event.component.items = this.filterRecords(ports.data, text);
      event.component.endSearch();
    });
  }

  filterRecords(arrArg: any[], text: string) {
    return arrArg.filter((item) => {
      return item.title.toLowerCase().indexOf(text) !== -1;
    });
  }

  infinitescroll(
    event: { component: IonicSelectableComponent; text: string },
    record: PaginatedResponse<any>,
    fn: CallableFunction,
  ) {
    const text = (event.text || '').trim().toLowerCase();
    // There're no more ports - disable infinite scroll.
    if (record.meta.current_page > record.meta.last_page) {
      event.component.disableInfiniteScroll();
      return;
    }
    const payload: any = {
      per_page: interestsPerPage,
      page: record.meta.current_page,
      sort: 'title',
      direction: 'asc',
    };

    fn(payload).subscribe((items: PaginatedResponse<InterestsModel>) => {
      setTimeout(() => {
        items.data = event.component.items.concat(items.data);
        record.meta.current_page++;
        event.component.items = items.data;
        event.component.endInfiniteScroll();
      }, 2000);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
