import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from '../config';

@Injectable()
export class PushSubscriptionService {
  private subscription: any;

  constructor(private http: HttpClient) {}

  setSubscription(subscription: any) {
    this.subscription = subscription;

    return this.http
      .post(`${Config.baseUrl}/notification/subscribe`, {
        subscription: this.subscription,
      })
      .subscribe();
  }

  unsubscribe(subscription: any) {
    this.subscription = subscription;

    return this.http
      .post(`${Config.baseUrl}/notification/unsubscribe`, {
        subscription: this.subscription,
      })
      .subscribe();
  }

  toggle(enable: boolean) {
    return this.http.post(`${Config.baseUrl}/notification/toggle`, { enable });
  }
}
