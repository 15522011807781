import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { currentStudiesPerPage } from '../../constants/general.constant';
import { NewrequestModel } from '../../models/new-request.model';
import { PaginatedResponse } from '../../models/paginated-response.model';
import { Nationality } from '../../models/user.model';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'studinty-new-request-modal',
  templateUrl: './new-request-modal.component.html',
  styleUrls: ['./new-request-modal.component.scss'],
})
export class NewRequestModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  currentStudies: PaginatedResponse<{
    id: number;
    title: string;
  }>;

  @Input() type: string;
  @Input() selectedCountry;
  @Input() nationalities: PaginatedResponse<Nationality>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected translate: TranslateService,
    public modalCtrl: ModalController,
    public toastController: ToastController,
    private userService: UserService,
    private errorHandler: ErrorHandlerService,
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(
        null,
        Validators.compose([Validators.required]),
      ),
      countries: new UntypedFormControl(this.selectedCountry || null),
      programs: new UntypedFormControl(null, [Validators.required]),
    });

    if (this.type === 'future-study') {
      this.getProgramsList();
    }
  }

  getProgramsList() {
    this.userService
      .getStudyPrograms({
        per_page: currentStudiesPerPage,
        direction: 'asc',
        sort: 'title',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.currentStudies = res;
        },
        error: (error: unknown) => {
          this.errorHandler.handle(error);
        },
      });
  }

  submit() {
    if (this.type === 'nationality') {
      this.form.controls.programs.setValidators([]);
      this.form.controls.programs.updateValueAndValidity();
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.form.value.title) {
      this.showToast('Title is Required').then((res) => {
        console.warn(res);
      });
      return;
    }

    const payload = {
      title: this.form.value.title,
      requested_id:
        this.type === 'future-study'
          ? this.form.value?.programs?.id
          : this.form.value?.countries?.id,
    };

    this.userService
      .addNewRequest(payload, this.type)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (req: NewrequestModel) => {
          this.dismiss();
          this.showToast(this.translate.instant(marker('NewRequest.Success')));
        },
        error: (error: unknown) => {
          this.dismiss();
          this.errorHandler.handle(error);
        },
      });
  }

  dismiss() {
    this.modalCtrl.dismiss().catch((reason) => {
      console.warn(reason);
    });
  }

  async showToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom',
    });
    await toast.present();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
