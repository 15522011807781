import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { Config } from '../config';
import { studintyAccessToken } from '../constants/general.constant';

import { AuthService } from './auth.service';
import { ErrorHandlerService } from './error-handler.service';
import { PusherService } from './pusher.service';
import { TokenStoreService } from './token-store.service';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  private locale: string;
  private translate: TranslateService;
  alert;

  constructor(
    private authService: AuthService,
    private alertCtrl: AlertController,
    private injector: Injector,
    private storage: Storage,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private router: Router,
    private pusher: PusherService,
    private errorService: ErrorHandlerService,
    private tokenStoreService: TokenStoreService,
  ) {
    setTimeout(() => {
      this.translate = injector.get(TranslateService);

      this.locale = this.translate.currentLang;

      this.translate.onLangChange.subscribe({
        next: (event: LangChangeEvent) => {
          this.locale = event.lang;
        },
        error: (error: unknown) => {
          this.errorService.handle(error);
        },
      });
    });
  }

  async showToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'bottom',
    });
    await toast.present();
  }

  private logout401() {
    this.storage.get(studintyAccessToken).then((token) => {
      this.pusher.unBindNotificationCounter(token?.user?.id);
      this.tokenStoreService.updateToken(null);
      this.authService.logout().subscribe({
        next: () => {
          this.router.navigate(['auth/login'], { replaceUrl: true });
        },
        error: (error: unknown) => {
          this.errorService.handle(error);
        },
      });
    });
  }

  private sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    token: string = null,
    count: number = 0,
  ): Observable<HttpEvent<any>> {
    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
      });
    }

    return next.handle(req).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status || 0) {
            // Connection error, delay by tries * 500ms and try again 5 times
            case 0:
            case -1:
              if (count < 5) {
                return timer(count * 500).pipe(
                  first(),
                  switchMap(() =>
                    this.sendRequest(req, next, token, count + 1),
                  ),
                );
              }
              break;
            case 401:
              this.logout401();
              break;
          }
        }
        // Forward all remaining errors
        return throwError(error);
      }),
    );
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf(Config.baseUrl) !== 0) {
      return next.handle(req);
    }

    if (this.locale && !req.headers.has('X-Locale')) {
      req = req.clone({
        headers: req.headers.set('X-Locale', this.locale),
      });
    }

    let observable: Observable<string>;
    if (req.url.indexOf(`${Config.baseUrl}/auth`) !== 0) {
      observable = this.tokenStoreService.token.pipe(
        map((token) => token && token.access_token),
      );
    } else {
      observable = of(null);
    }

    return observable.pipe(
      switchMap((token: string) => this.sendRequest(req, next, token)),
    );
  }
}
