import { Location } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { EventsModel } from '../../models/events.model';
import { HousingModel } from '../../models/housing.model';
import { MarketPlaceModel } from '../../models/market-place.model';
import { PaginatedResponse } from '../../models/paginated-response.model';
import { UserModel } from '../../models/user.model';
import { BasePage } from '../../pages/base.page';
import { AuthService } from '../../services/auth.service';
import { ChatService } from '../../services/chat.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { EventsService } from '../../services/events.service';
import { HousingService } from '../../services/housing.service';
import { MarketPlaceService } from '../../services/market-place.service';
import { PhotoViewerService } from '../../services/photo-viewer.service';
import { PostService } from '../../services/post.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'studinty-user-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
})
export class ProfileDetailComponent extends BasePage implements OnDestroy {
  profileId: number;
  routeSegment: string;
  userDetail: UserModel;
  @Input() defaultRoute: string;
  events: PaginatedResponse<any>;
  housings: PaginatedResponse<HousingModel>;
  marketPlaces: PaginatedResponse<MarketPlaceModel>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private location: Location,
    protected platform: Platform,
    private route: ActivatedRoute,
    loadingCtrl: LoadingController,
    private chatService: ChatService,
    protected userService: UserService,
    protected postService: PostService,
    protected authService: AuthService,
    protected translate: TranslateService,
    public modalController: ModalController,
    private alertController: AlertController,
    private errorHandler: ErrorHandlerService,
    private housingService: HousingService,
    private eventsService: EventsService,
    private photoViewer: PhotoViewerService,
    private marketPlaceService: MarketPlaceService,
  ) {
    super(platform, userService, authService, translate, loadingCtrl);
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe({
      next: (params) => {
        this.profileId = params.user_id;
        this.routeSegment = params.pathSegment
          ? params.pathSegment + '?user_id=' + params.user_id
          : undefined;
        this.onInitFetch();
        this.afterViewInitFetch();
      },
      error: (error: unknown) => {
        this.errorHandler.handle(error);
      },
    });
  }

  onInitFetch() {
    forkJoin([
      this.housingService.get({ per_page: 5, user_id: this.profileId }),
      this.eventsService.get({
        per_page: 5,
        group_by_date: false,
        user_id: this.profileId,
      }),
      this.marketPlaceService.get({ per_page: 5, user_id: this.profileId }),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result: any) => {
          this.housings = result[0];
          this.events = result[1];
          this.marketPlaces = result[2];
        },
        error: (error: unknown) => {
          this.errorHandler.handle(error);
        },
      });
  }

  afterViewInitFetch() {
    this.showLoader().then(() => {
      this.userService
        .getUpdatedUser(this.profileId)
        .pipe(
          finalize(() => {
            this.hideLoader();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (res) => {
            this.userDetail = res;
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    });
  }

  /**
   * send chat request
   *
   * @param id number, requried to send chat request
   * @param messsage string, message for chat request
   * @returns void
   */
  sendChatRequest(id: number, message: string): void {
    this.showLoader().then(() => {
      this.chatService
        .sendChatRequestFromUser(id, message)
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (response) => {
            this.userDetail.chat_request = {
              status: 'pending',
            };
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    });
  }

  async sendRequest(item: UserModel) {
    if (item.chat_request && item.chat_request.status === 'accepted') {
      this.router.navigate(['chat-detail'], {
        queryParams: { chat_id: item.chat_request.user.id },
      });
    } else if (item.chat_request && item.chat_request.status === 'pending') {
      this.showToast(this.translate.instant(marker('Contact.Pending')));
    } else {
      const alert = await this.alertController.create({
        header: this.translate.instant(marker('MatchingUsers.NotAContact')),
        inputs: [
          {
            name: 'message',
            placeholder: this.translate.instant(
              marker('MatchingUsers.WriteMessage'),
            ),
            type: 'textarea',
            value: this.translate.instant(marker('Message.Hi')),
          },
        ],
        buttons: [
          {
            text: this.translate.instant(marker('Buttons.Close')),
            role: 'cancel',
          },
          {
            text: this.translate.instant(marker('Buttons.Send')),
            handler: (data) => {
              if (data.message === '') {
                this.showToast(
                  this.translate.instant(marker('MatchingUsers.EmptyMessage')),
                );
              }
              this.sendChatRequest(item.id, data.message);
            },
          },
        ],
      });
      await alert.present();
    }
  }

  /**
   * Open the chat detail
   * @param param single rec of chat data
   */
  openChatDetail(param) {
    param.user_id = param.id;
    this.router.navigate(['chat-detail'], {
      queryParams: { chat_id: param.chat_id },
    });
  }

  /**
   * It will navigate user to provided route
   *
   * @param route String, name of the route
   * @returns void
   */
  navigateTo(route: string): void {
    this.router.navigate([route], {
      queryParams: { profileId: this.profileId, pathSegment: 'profile-detail' },
    });
  }

  /**
   * Navigate to report-user
   *
   * @returns void
   */
  report(): void {
    this.router.navigate(['report'], {
      queryParams: { userId: this.profileId },
    });
  }

  /**
   * Block user
   *
   * @params chatId number, chatId of contact
   * @returns void
   */
  async block(chatId: any, profile: any = {}) {
    const alert = await this.alertController.create({
      header: this.translate.instant(marker('ProfileDetail.BlockContact')),
      message: this.translate
        .instant(marker('ProfileDetail.BlockConfirm'))
        .replace('<username>', profile.full_name),
      buttons: [
        {
          text: this.translate.instant(marker('Buttons.Cancel')),
        },
        {
          text: this.translate.instant(marker('Buttons.Block')),
          handler: () => {
            this.showLoader();
            if (this.userDetail?.chat_request?.status) {
              this.chatService
                .changeStatus(chatId, { status: 'block' })
                .pipe(
                  finalize(() => this.hideLoader()),
                  takeUntil(this.destroy$),
                )
                .subscribe({
                  next: (response) => {
                    this.routeBaseOnChatDetail();
                  },
                  error: (error: unknown) => this.errorHandler.handle(error),
                });
            } else {
              this.blockUnknownUser();
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async blockUnknownUser() {
    this.chatService
      .blockUnknownUser(this.userDetail?.id)
      .pipe(
        finalize(() => this.hideLoader()),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response) => {
          this.routeBaseOnChatDetail();
        },
        error: (error: unknown) => this.errorHandler.handle(error),
      });
  }

  /**
   * Removes contact
   *
   * @param chatId number, remove contact
   * @returns void
   */
  contactRemoval(chatId: any): void {
    this.showLoader().then(() => {
      this.chatService
        .contactRemoval(chatId)
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (response) => {
            this.userDetail.chat_request = null;
            this.routeBaseOnChatDetail();
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    });
  }

  chatRequestStatus(chatId: any, status: string) {
    this.showLoader().then(() => {
      this.chatService
        .changeStatus(chatId, { status })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res: any) => {
            this.hideLoader();
            if (res && res.chat_request.status === 'accept') {
              this.router.navigate(['chat-detail'], {
                queryParams: { chat_id: res.id },
              });
            }
          },
          error: (error: unknown) => {
            this.hideLoader();
            this.errorHandler.handle(error);
          },
        });
    });
  }

  scrollToView(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  /**
   * navigate to item detail page
   * @param item HousingModel item
   * @returns void
   */
  gotoHousingDetail(item: HousingModel): void {
    const route = this.routeSegment ? 'housing-detail' : 'tabs/housing/detail';
    // ref query param to set back button route in housing detail page
    this.router.navigate([route], {
      queryParams: { item_id: item.id, ref: this.router.url },
    });
  }

  /**
   * navigate to item detail page
   * @param item EventsModel
   * @returns void
   */
  gotoItemDetail(item: EventsModel): void {
    const route = this.routeSegment ? 'event-detail' : 'tabs/events/show';
    this.router.navigate([route], {
      queryParams: { item_id: item.id, ref: this.router.url },
    });
  }

  /**
   * navigate to item detail page
   * @param item MarketPlaceModel
   * @returns void
   */
  gotoProductDetail(item: MarketPlaceModel): void {
    const route = this.routeSegment
      ? 'product-detail'
      : 'tabs/market-place/detail';
    // ref query param to set back button route in events detail page
    this.router.navigate([route], {
      queryParams: { item_id: item.id, ref: this.router.url },
    });
  }

  openContacts() {
    this.router.navigate(['contact'], {
      queryParams: {
        profileId: this.profileId,
        ref: 'profile-detail',
      },
    });
  }

  routeBaseOnChatDetail() {
    // to update the user connections count
    this.userService
      .getUpdatedUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.router.navigate(['tabs', 'matching-users']);
        },
        error: (error: unknown) => {
          this.errorHandler.handle(error);
        },
      });
  }

  async previewImage(url: string) {
    await this.photoViewer.show(url);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
