import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import {
  NativeGeocoder,
  NativeGeocoderOptions,
  NativeGeocoderResult,
} from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Platform } from '@ionic/angular';
import { merge, Observable, of, Subject, timer } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mergeMap,
  publishReplay,
  refCount,
  retryWhen,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { Config } from '../config';
import { NewrequestModel } from '../models/new-request.model';
import { PaginatedResponse } from '../models/paginated-response.model';
import {
  FutureStudiesModel,
  InterestsModel,
  LanguagesModel,
  MatchesModel,
  Nationality,
  ReportUserPaylaod,
  UniversityModel,
  UserFiltersModel,
  UserModel,
  UserProfilePayloadModel,
} from '../models/user.model';

import { AuthService } from './auth.service';
import { ErrorHandlerService } from './error-handler.service';
import { HttpService } from './http.service';
import { TokenStoreService } from './token-store.service';
import { other } from '../constants/general.constant';

@Injectable()
export class UserService extends HttpService {
  public user: UserModel;
  public userDetail: UserModel;
  private readonly refreshUser = new Subject<boolean>();
  private readonly user$: Observable<UserModel>;
  private citiesCache: {
    [key: number]: PaginatedResponse<Nationality>;
  } = {};
  private interestsCache: { [key: number]: PaginatedResponse<InterestsModel> } =
    {};
  private userSubject = new Subject<UserModel>();

  options: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5,
  };

  constructor(
    http: HttpClient,
    authService: AuthService,
    private geolocation: Geolocation,
    private errorHandler: ErrorHandlerService,
    private tokenStoreService: TokenStoreService,
    private nativeGeocoder: NativeGeocoder,
    protected platform: Platform,
  ) {
    super(http);
    authService.onLoginChange.subscribe({
      next: () => {
        this.user = null;
      },
      error: (error: unknown) => {
        this.errorHandler.handle(error);
      },
    });

    this.user$ = merge(
      authService.onLoginChange,
      this.refreshUser.pipe(switchMap(() => authService.loggedIn)),
    ).pipe(
      startWith(false),
      distinctUntilChanged(),
      switchMap((loggedIn) =>
        loggedIn ? this.http.get<UserModel>(this.buildUrl('me')) : of(null),
      ),
      retryWhen((errors) =>
        errors.pipe(mergeMap((_, attempt) => timer((attempt + 1) * 500))),
      ),
      publishReplay(1),
      refCount(),
    );
  }

  get userData() {
    return this.user$;
  }

  broadCastUser(user: UserModel) {
    this.userSubject.next(user);
  }

  getUser() {
    return this.userSubject.asObservable();
  }

  get(): Observable<UserModel> {
    if (this.user) {
      return of(this.user);
    }

    return this.http
      .get<UserModel>(this.buildUrl('user-profile'))
      .pipe(tap((user) => (this.user = user)));
  }

  getUpdatedUser(userId?: number): Observable<UserModel> {
    return this.http
      .get<UserModel>(this.buildUrl('user-profile', userId ? userId : ''))
      .pipe(
        tap((user) => {
          if (!userId) {
            this.user = user;
            this.broadCastUser(user);
          }
        }),
      );
  }

  updateUser(user: UserProfilePayloadModel) {
    return this.http.put(this.buildUrl(`user-profile`), user);
  }

  updatePassword(params: any) {
    return this.http.post(this.buildUrl('update-password'), params);
  }

  getNationalities = (params?: any) => {
    return this.http.get<PaginatedResponse<Nationality>>(
      this.buildUrl('nationalities'),
      {
        params: this.serialize(params),
      },
    );
  };

  getCities = (params?: any) => {
    return this.http.get<PaginatedResponse<Nationality>>(
      this.buildUrl('city', 'list'),
      {
        params: this.serialize(params),
      },
    );
  };

  getUniversities = (
    params?: any,
  ): Observable<PaginatedResponse<UniversityModel>> => {
    return this.http.get<PaginatedResponse<UniversityModel>>(
      this.buildUrl('universities'),
      {
        params: this.serialize(params),
      },
    );
  };

  getLanguages = (
    params?: any,
  ): Observable<PaginatedResponse<LanguagesModel>> => {
    return this.http.get<PaginatedResponse<LanguagesModel>>(
      this.buildUrl('languages'),
      {
        params: this.serialize(params),
      },
    );
  };

  getInterests(params?: any) {
    if (params && this.interestsCache[params.page]) {
      return of(this.interestsCache[params.page]);
    }
    return this.http
      .get<PaginatedResponse<InterestsModel>>(this.buildUrl('interests'), {
        params: this.serialize(params),
      })
      .pipe(
        tap((response) => {
          this.interestsCache[params?.page || 1] = response;
        }),
        map((response: any) => {
          const subInterests = [];
          response.data.forEach((interestItem) => {
            interestItem.subInterests.forEach((subInterest) => {
              subInterest.parentInterest = {
                id: interestItem.id,
                title: interestItem.title,
              };
              subInterests.push(subInterest);
            });
          });
          response.data = subInterests;
          return response;
        }),
      );
  }

  getFutureStudies(params?: any) {
    return this.http
      .get<FutureStudiesModel>(this.buildUrl('future-studies'), {
        params: this.serialize(params),
      })
      .pipe(
        map((response: any) => {
          const subInterests = [];
          response.data.forEach((interestItem) => {
            interestItem.children.forEach((subInterest) => {
              subInterest.parentInterest = {
                id: interestItem.id,
                title: interestItem.title,
              };
              subInterests.push(subInterest);
            });
          });
          response.data = [
            ...subInterests,
            {
              id: 0,
              title: other,
              parentInterest: { id: 0, title: other },
            },
          ];
          return response;
        }),
      );
  }

  getCurrentStudies(params?: any) {
    return this.http
      .get<PaginatedResponse<InterestsModel>>(this.buildUrl('study', 'list'), {
        params: this.serialize(params),
      })
      .pipe(
        tap((response) => {
          this.interestsCache[params?.page || 1] = response;
        }),
        map((response: any) => {
          const subInterests = [];
          response.data.forEach((interestItem) => {
            interestItem.programs.forEach((subInterest) => {
              subInterest.parentInterest = {
                id: interestItem.id,
                title: interestItem.title,
              };
              subInterests.push(subInterest);
            });
          });
          response.data = subInterests;
          return response;
        }),
      );
  }

  getStudyPrograms(params?: any) {
    return this.http.get<PaginatedResponse<InterestsModel>>(
      this.buildUrl('future-studies'),
      {
        params: this.serialize(params),
      },
    );
  }

  /**
   * Return the current location of the user
   * @param callback return object of latitude and longitude
   */
  getCurrentLocation(callback) {
    this.geolocation
      .getCurrentPosition()
      .then((resp: any) => {
        if (Config.fakeLocation) {
          callback(Config.fakeLocationData);
        } else {
          if (this.platform.is('android') || this.platform.is('ios')) {
            this.nativeGeocoder
              .reverseGeocode(
                resp.coords.latitude,
                resp.coords.longitude,
                this.options,
              )
              .then((result: NativeGeocoderResult[]) => {
                resp.location = result[0];
                callback(resp);
              })
              .catch((error: any) => console.log(error));
          } else {
            callback(resp);
          }
        }
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  /**
   * Report user to admin
   *
   * @param request Requst of type ReportUserPayload
   */
  reportUser(request: ReportUserPaylaod) {
    return this.http.post(this.buildUrl('report'), request);
  }

  setFilters(body: ReportUserPaylaod) {
    return this.http.post(this.buildUrl(`settings/set-filter`), body);
  }

  getFilters() {
    return this.http.get<UserFiltersModel>(this.buildUrl(`settings/filter`));
  }

  getMatches(params: any) {
    return this.http.get<PaginatedResponse<MatchesModel>>(
      this.buildUrl(`user-profile/matches`),
      {
        params: this.serialize(params),
      },
    );
  }

  getLocals(params?: any) {
    return this.http.get<PaginatedResponse<MatchesModel>>(
      this.buildUrl(`user-profile/local/matches`),
      {
        params: this.serialize(params),
      },
    );
  }

  removeAccount() {
    return this.http
      .delete<UserFiltersModel>(this.buildUrl('me'))
      .pipe(switchMap(() => this.tokenStoreService.updateToken(null)));
  }

  /**
   * send online user call, to maitain online
   * @param userId login user id
   */
  onlineUser(userId: number) {
    return this.http.put(
      this.buildUrl(`user-profile/online/${userId}/status`),
      {},
    );
  }

  pageVisited(body) {
    return this.http.post(this.buildUrl('user-profile/page-visited'), body);
  }

  setUserToNull() {
    this.user = null;
  }

  addNewRequest(request: NewrequestModel, type) {
    return this.http.post(this.buildUrl(`add/new/request/${type}`), request);
  }

  updateFutureStudyProgram(request) {
    return this.http.post(this.buildUrl(`study-locations`), request);
  }

  removeFutureStudyProgram(id: number) {
    return this.http.delete(this.buildUrl(`study-locations/${id}`));
  }

  changePassword(payload) {
    return this.http.post(this.buildUrl(`change-password`), payload);
  }
}
