import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GoolgeSDKService {
  auth2: any;
  protected readonly googleAuth = new Subject<boolean>();
  constructor() {}
  getSDKInstance() {
    return this.googleAuth.asObservable();
  }

  googleSDK() {
    // tslint:disable-next-line: only-arrow-functions
    (function (d, s, id, self) {
      let js: any = d.getElementsByTagName(s)[0];
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        console.log('fire event');
        setTimeout(() => {
          self.googleAuth.next(true);
        }, 300);
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk', this);
    // tslint:disable-next-line: no-string-literal
    window['googleSDKLoaded'] = () => {
      // tslint:disable-next-line: no-string-literal
      window['gapi'].load('auth2', () => {
        // tslint:disable-next-line: no-string-literal
        this.auth2 = window['gapi'].auth2.init({
          client_id: '12923807382-suoi71m2cgfi5uci7ih63vu9ab4djat4',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
        });
        this.googleAuth.next(true);
      });
    };
  }
}
