import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export class BaseItem {
  isLoading = false;

  constructor(
    private loadingCtrl: LoadingController,
    protected translate: TranslateService,
    private toastController: ToastController,
  ) {}

  async showLoader() {
    this.isLoading = true;
    return await this.loadingCtrl
      .create({
        message: this.translate.instant(marker('Refresh.Loading')),
      })
      .then((loader) => {
        loader.present().then(() => {
          if (!this.isLoading) {
            loader.dismiss();
          }
        });
      });
  }

  async hideLoader() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss();
  }

  async showToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom',
    });
    await toast.present();
  }
}
