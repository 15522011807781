import { environment } from '../environments/environment';

export const Config = {
  siteUrl: environment.siteUrl,
  apiUrl: environment.apiUrl,
  baseUrl: environment.baseUrl,
  oneSignalId: environment.oneSignalId,
  pusher: environment.pusher,
  fakeLocation: environment.fakeLocation,
  fakeLocationData: environment.fakeLocationData,
};

export enum PurchaseType {
  ANDROID = 'android-playstore',
  IOS = 'ios-appstore',
}
