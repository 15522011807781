import { NgModule } from '@angular/core';

import { LocalizedDatePipe } from './Localized-date/localized-date.pipe';
import { CalculateAgePipe } from './calculate-age/calculate-age.pipe';
import { DateAgoPipe } from './date-ago/date-ago.pipe';
import { NormalizeUrlPipe } from './normalize-url/normalize-url.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';

@NgModule({
  declarations: [
    SafeUrlPipe,
    CalculateAgePipe,
    LocalizedDatePipe,
    NormalizeUrlPipe,
    DateAgoPipe,
  ],
  imports: [],
  exports: [
    SafeUrlPipe,
    CalculateAgePipe,
    LocalizedDatePipe,
    NormalizeUrlPipe,
    DateAgoPipe,
  ],
})
export class PipesModule {}
