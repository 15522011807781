import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    data: { preload: true },
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./pages/terms/terms.module').then((m) => m.TermsPageModule),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./pages/terms/terms.module').then((m) => m.TermsPageModule),
  },
  {
    path: 'chat-detail',
    loadChildren: () =>
      import('./pages/chat-detail/chat-detail.module').then(
        (m) => m.ChatDetailPageModule,
      ),
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./pages/feedback/feedback.module').then(
        (m) => m.FeedbackPageModule,
      ),
  },
  {
    path: 'add-post',
    loadChildren: () =>
      import('./pages/add-post/add-post.module').then(
        (m) => m.AddPostPageModule,
      ),
  },
  {
    path: 'profile-detail',
    loadChildren: () =>
      import('./pages/profile-detail/profile-detail.module').then(
        (m) => m.ProfileDetailPageModule,
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./pages/report/report.module').then((m) => m.ReportPageModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule,
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.Tab1PageModule),
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./pages/notification/notification.module').then(
        (m) => m.NotificationPageModule,
      ),
  },
  {
    path: 'favorites',
    loadChildren: () =>
      import('./pages/favorites/favorites.module').then(
        (m) => m.FavoritesPageModule,
      ),
  },
  {
    path: 'matchings-intro',
    loadChildren: () =>
      import('./pages/intro/matchings-intro/matchings-intro.module').then(
        (m) => m.MatchingsIntroPageModule,
      ),
  },
  {
    path: 'housing-intro',
    loadChildren: () =>
      import('./pages/intro/housing-intro/housing-intro.module').then(
        (m) => m.HousingIntroPageModule,
      ),
  },
  {
    path: 'events-intro',
    loadChildren: () =>
      import('./pages/intro/events-intro/events-intro.module').then(
        (m) => m.EventsIntroPageModule,
      ),
  },
  {
    path: 'products-intro',
    loadChildren: () =>
      import('./pages/intro/products-intro/products-intro.module').then(
        (m) => m.ProductsIntroPageModule,
      ),
  },
  {
    path: 'base-intro',
    loadChildren: () =>
      import('./pages/intro/base-intro/base-intro.module').then(
        (m) => m.BaseIntroPageModule,
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutPageModule),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (m) => m.ContactUsPageModule,
      ),
  },
  {
    path: 'network-issue',
    loadChildren: () =>
      import('./pages/network-issue/network-issue.module').then(
        (m) => m.NetworkIssuePageModule,
      ),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'participants',
    loadChildren: () =>
      import('./pages/participants/participants.module').then(
        (m) => m.ParticipantsPageModule,
      ),
  },
  {
    path: 'locals',
    loadChildren: () =>
      import('./pages/locals/locals.module').then((m) => m.LocalsPageModule),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule,
      ),
  },
  { path: '**', component: NotFoundComponent, data: { animation: 'NotFound' } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
