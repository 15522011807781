import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FeedbackModel } from '../models/feedback.model';

import { HttpService } from './http.service';

@Injectable()
export class FeedbackService extends HttpService {
  /**
   * Create a new FeedbackService instance
   * @param http http angular client
   */
  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * This will be used to send post request to the server
   * This will create new FeedbackModel resource
   *
   * @param feedback feedback text
   * @return An `Observable` of the response, with the response body as a JSON object.
   */
  create(feedback: FeedbackModel) {
    return this.http.post(this.buildUrl(`feedback`), feedback);
  }
}
