import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'studinty-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.css'],
})
export class ImagePreviewComponent {
  @Input() image: string;

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss().catch((error) => {
      console.warn(error);
    });
  }
}
