import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { RemoveAdReasons } from '../../constants/general.constant';
import { MarketPlaceModel } from '../../models/market-place.model';
import { UserModel } from '../../models/user.model';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { MarketPlaceService } from '../../services/market-place.service';
import { PhotoViewerService } from '../../services/photo-viewer.service';
import { BaseItem } from '../base-item';

@Component({
  selector: 'studinty-marketplace-list-item',
  templateUrl: './marketplace-list-item.component.html',
  styleUrls: ['./marketplace-list-item.component.scss'],
})
export class MarketplaceListItemComponent
  extends BaseItem
  implements OnDestroy
{
  @Input() user: UserModel;
  @Input() routeSegment: string;
  @Input() item: MarketPlaceModel;
  @Output() itemDeleted: EventEmitter<number> = new EventEmitter();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private errorHandler: ErrorHandlerService,
    loadingCtrl: LoadingController,
    protected translate: TranslateService,
    private photoViewer: PhotoViewerService,
    protected alertController: AlertController,
    toastController: ToastController,
    private marketPlaceService: MarketPlaceService,
  ) {
    super(loadingCtrl, translate, toastController);
  }

  /**
   * navigate to item detail page
   * @param item MarketPlaceModel
   * @returns void
   */
  gotoItemDetail(item: MarketPlaceModel): void {
    const route = this.routeSegment
      ? 'product-detail'
      : 'tabs/market-place/detail';
    // ref query param to set back button route in events detail page
    this.router.navigate([route], {
      queryParams: { item_id: item.id, ref: this.router.url },
    });
  }

  /**
   * toggle favourite property of item
   * @param item MarketPlaceModel
   * @returns void
   */
  toggleFavorite(item: MarketPlaceModel) {
    this.showLoader().then(() => {
      this.marketPlaceService
        .toggleFavorite(item.id)
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (resp) => {
            const message = this.item.is_favorited
              ? marker('MarketPlace.Success.UnFavourite')
              : marker('MarketPlace.Success.Favourite');
            this.showToast(this.translate.instant(message));
            item.is_favorited = !item.is_favorited;
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    });
  }

  /**
   * navigate to item create page
   * @param item MarketPlaceModel
   * @param openMode This can be either create or update
   * @returns void
   */
  gotoItemCreate(item = null, openMode: string): void {
    this.router.navigate(
      [
        'tabs/market-place/add',
        {
          mode: openMode,
        },
      ],
      { state: { item } },
    );
  }

  /**
   * show alert popup to get reason
   * @param itemId id of item
   * @returns void
   */
  async deleteConfirm(itemId: number) {
    const alert = await this.alertController.create({
      header: this.translate.instant(marker('MarketPlace.Delete.Header')),
      message: this.translate.instant(marker('MarketPlace.Delete.Message')),
      inputs: [
        {
          name: 'reason',
          type: 'radio',
          label: this.translate.instant(
            marker('MarketPlace.Delete.Reason.Sold'),
          ),
          value: RemoveAdReasons.AdSold,
          checked: true,
        },
        {
          name: 'reason',
          type: 'radio',
          label: this.translate.instant(
            marker('MarketPlace.Delete.Reason.NotSold'),
          ),
          value: RemoveAdReasons.AdNotSold,
          checked: false,
        },
        {
          name: 'reason',
          type: 'radio',
          label: this.translate.instant(
            marker('MarketPlace.Delete.Reason.Other'),
          ),
          value: RemoveAdReasons.Nothing,
          checked: false,
        },
      ],
      buttons: [
        {
          text: this.translate.instant(marker('Buttons.Cancel')),
        },
        {
          text: this.translate.instant(marker('Buttons.Delete')),
          handler: (reason) => {
            this.deleteItem(itemId, reason);
          },
        },
      ],
    });
    await alert.present();
  }

  /**
   * delete item from storage
   * @param itemId id of item
   * @param reason this is the reason to remove an id (selected option as string)
   * @returns void
   */
  deleteItem(itemId: number, reason: string) {
    this.showLoader();
    this.marketPlaceService
      .delete(itemId, { reason })
      .pipe(
        finalize(() => this.hideLoader()),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (resp) => {
          this.itemDeleted.emit(itemId);
        },
        error: (error: unknown) => {
          this.errorHandler.handle(error);
        },
      });
  }

  async previewImage(url: string) {
    await this.photoViewer.show(url);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
