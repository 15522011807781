import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { Observable } from 'rxjs';

import { Config } from '../config';
import { CurrencyModel } from '../models/housing.model';
import { ReportReasonsResponseModel } from '../models/user.model';

import { HttpService } from './http.service';

@Injectable()
export class GenralService extends HttpService {
  isLoading = false;
  public cordova = false;
  notificationCounter = 0;

  constructor(
    http: HttpClient,
    private platform: Platform,
    private diagnostic: Diagnostic,
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private toastController: ToastController,
    private androidPermissions: AndroidPermissions,
  ) {
    super(http);
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.cordova = true;
    }
  }

  getReportReasons(
    params?: any,
  ): Observable<Array<ReportReasonsResponseModel>> {
    return this.http.get<Array<ReportReasonsResponseModel>>(
      `${Config.apiUrl}/report/reasons`,
      {
        params: this.serialize(params),
      },
    );
  }

  reportAd(id: number, adType: string, body) {
    return this.http.post(this.buildUrl(`${adType}/${id}/report`), body);
  }

  reportComment(id: number, adType: string, body) {
    return this.http.post(
      this.buildUrl(`timeline/${adType}/${id}/report`),
      body,
    );
  }

  extendAd(id: number, adType: string) {
    return this.http.get(this.buildUrl(`${adType}/${id}/extend-period`));
  }

  getLoggedInUser(user$): any {
    const promise = new Promise((resolve, reject) => {
      user$.subscribe((data) => {
        resolve(data);
      });
    });
    return promise;
  }

  /**
   * It will post single image to server
   *
   * @param request Post body contains "images type string"
   * @param model string, name of model
   * @param modelId number, record no of that model
   */
  postImageToServer(request: any, model: string, modelId: number) {
    return this.http.post(
      this.buildUrl(`media/${model}/save`, modelId),
      request,
    );
  }

  getComponentByType(address: Address, type: string): AddressComponent {
    if (!type) {
      return null;
    }

    if (
      !address ||
      !address.address_components ||
      address.address_components.length === 0
    ) {
      return null;
    }

    type = type.toLowerCase();

    for (const comp of address.address_components) {
      if (!comp.types || comp.types.length === 0) {
        continue;
      }

      if (comp.types.findIndex((x) => x.toLowerCase() === type) > -1) {
        return comp;
      }
    }

    return null;
  }

  /**********************************************************************/
  /*************************** General Methods **************************/

  /**********************************************************************/

  /**
   * checks for permission on android only
   */
  checkPermission(callback) {
    if (this.cordova && this.platform.is('android')) {
      this.androidPermissions
        .checkPermission(
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
        )
        .then(
          (result) => {
            console.log('Has location permission?', result.hasPermission);
            if (!result.hasPermission) {
              this.androidPermissions
                .requestPermission(
                  this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
                )
                .then((permResp) => {
                  console.log('req location perm response: ', permResp);
                  if (permResp.hasPermission) {
                    callback(true);
                  } else {
                    this.showToast(
                      this.translate.instant(marker('LocationRejected')),
                    );
                    callback(false);
                  }
                });
            } else {
              callback(true);
            }
          },
          (err) =>
            this.androidPermissions.requestPermission(
              this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
            ),
        );
    } else {
      callback(true);
    }
  }

  async showToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('Buttons.Ok'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    toast.present();
  }

  gpsStatus() {
    if (this.cordova) {
      return this.diagnostic.isLocationEnabled();
    }
  }

  async showLoader() {
    this.isLoading = true;
    return await this.loadingCtrl
      .create({
        message: this.translate.instant(marker('Refresh.Loading')),
      })
      .then((loader) => {
        loader.present().then(() => {
          if (!this.isLoading) {
            loader.dismiss();
          }
        });
      });
  }

  async hideLoader() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss();
  }

  getCurrencies(params?: any): Observable<any> {
    return this.http.get<Array<CurrencyModel>>(`${Config.apiUrl}/currencies`, {
      params: this.serialize(params),
    });
  }

  sessionStarted() {
    return this.http.post(this.buildUrl('session/started'), {});
  }

  sessionEnded() {
    return this.http.post(this.buildUrl('session/ended'), {});
  }
}
