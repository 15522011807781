import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PaginatedResponse } from '../models/paginated-response.model';
import {
  CommentsModel,
  LikeResponseModel,
  PostDetailResponseModel,
} from '../models/post.model';

import { HttpService } from './http.service';

@Injectable()
export class PostService extends HttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  likePost(
    postId: number,
    section: string,
    request = {},
  ): Observable<LikeResponseModel> {
    return this.http.post<LikeResponseModel>(
      this.buildUrl(`timeline/${section}/like`, postId),
      request,
    );
  }

  favPost(postId: number, section: string, request = {}) {
    return this.http.post(
      this.buildUrl(`favorite/toggle/${section}`, postId),
      request,
    );
  }

  commentPost(postId: number, request: CommentsModel, section?: string) {
    return this.http.post(
      this.buildUrl(`timeline/${section}/comment`, postId),
      request,
    );
  }

  createPost(request: any) {
    return this.http.post(this.buildUrl('post/store'), request);
  }

  updatePost(postId: number, request: any) {
    return this.http.put(this.buildUrl('post', postId), request);
  }

  fetchPostDetail(postId: number): Observable<PostDetailResponseModel> {
    return this.http.get<PostDetailResponseModel>(
      this.buildUrl('post', postId),
    );
  }

  fetchAllComments(
    postId: number,
    section: string,
    param?: any,
  ): Observable<PaginatedResponse<CommentsModel>> {
    return this.http.get<PaginatedResponse<CommentsModel>>(
      this.buildUrl(`timeline/${section}/comment/${postId}/list`),
      {
        params: this.serialize(param),
      },
    );
  }

  deletePost(postId: number, params?: any) {
    return this.http.delete(this.buildUrl('post', postId), {
      params: this.serialize(params),
    });
  }

  deleteComment(commentId: string) {
    return this.http.delete(
      this.buildUrl(`timeline/comment/${commentId}/delete`),
    );
  }

  updateComment(commentId: string, text: string) {
    return this.http.put(
      this.buildUrl(`timeline/comment/${commentId}/update`),
      {
        text,
      },
    );
  }
}
