import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Config } from '../config';
import { EventsCategoriesModel, EventsModel } from '../models/events.model';
import { PaginatedResponse } from '../models/paginated-response.model';
import { CommentsModel } from '../models/post.model';
import { UserModel } from '../models/user.model';

import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends HttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  get(params?: any): Observable<any> {
    return this.http.get<Array<EventsModel>>(`${Config.apiUrl}/event-ad`, {
      params: this.serialize(params),
    });
  }

  show(id: number): Observable<any> {
    return this.http.get<EventsModel>(`${Config.apiUrl}/event-ad/${id}`);
  }

  fetchEventComments(eventId: number, params: any) {
    return this.http.get<PaginatedResponse<CommentsModel>>(
      `${Config.apiUrl}/timeline/event-ad/comment/${eventId}/list`,
      {
        params: this.serialize(params),
      },
    );
  }

  getFavorites(params?: any): Observable<any> {
    return this.http.get<Array<EventsModel>>(
      `${Config.apiUrl}/favorite/event-ad`,
      {
        params: this.serialize(params),
      },
    );
  }

  getCategories(params?: any): Observable<any> {
    return this.http.get<Array<EventsCategoriesModel>>(
      `${Config.apiUrl}/event-ad-categories`,
      {
        params: this.serialize(params),
      },
    );
  }

  toggleFavorite(id: number): Observable<any> {
    return this.http.post<string>(
      `${Config.apiUrl}/favorite/toggle/event-ad/${id}`,
      {},
    );
  }

  create(event: EventsModel) {
    return this.http.post(this.buildUrl(`event-ad`), event);
  }

  update(id: number, event: EventsModel) {
    return this.http.put(this.buildUrl(`event-ad/${id}`), event);
  }

  delete(id: number, params?: any) {
    return this.http.delete(this.buildUrl(`event-ad/${id}`), {
      params: this.serialize(params),
    });
  }

  joinOrOptout(id: number): Observable<any> {
    return this.http.get(`${Config.apiUrl}/event-ad/${id}/join-or-opt-out`, {});
  }

  getParticipants(id: number, params?: any): Observable<any> {
    return this.http.get<Array<UserModel>>(
      `${Config.apiUrl}/event-ad/${id}/participants`,
      {
        params: this.serialize(params),
      },
    );
  }
}
