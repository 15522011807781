import { Component, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  ActionSheetController,
  LoadingController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import * as moment from 'moment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { Config } from '../config';
import { maxImages, NationalityPerPage } from '../constants/general.constant';
import {
  MapConfigOptionModel,
  MapDataModel,
  MapModel,
} from '../models/map.model';
import { PaginatedResponse } from '../models/paginated-response.model';
import { CitiesModel, Nationality, UserModel } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { ImageUploadService } from '../services/image-upload.service';
import { MediaCaptureProvider } from '../services/media-capture';
import { UserService } from '../services/user.service';

@Component({
  template: '',
})
export class BasePage implements OnDestroy {
  public extractedData: MapModel = { data: [], filters: {} };
  protected destroy = new Subject<boolean>();
  protected readonly user$ = new ReplaySubject<UserModel>(1);

  user: UserModel;
  loggedIn = true;
  cordova = false;
  isDetail = false;
  isLoading = false;
  timeFormat = 'HH:mm:ss';
  dateFormat = 'YYYY-MM-DD';
  dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  selectedImages = [];
  imagesArray = [];
  nationalitySubscription: Subscription;
  public nationalities: PaginatedResponse<Nationality>;
  public cities: PaginatedResponse<CitiesModel>;

  constructor(
    protected platform: Platform,
    protected userService: UserService,
    protected authService: AuthService,
    protected translate: TranslateService,
    private loadingCtrl: LoadingController,
    private toastController?: ToastController,
    public mediaCapture?: MediaCaptureProvider,
    public imageUploadService?: ImageUploadService,
    public actionSheetCtrl?: ActionSheetController,
    public camera?: Camera,
  ) {
    authService.onLoginChange
      .pipe(
        switchMap((loggedIn) => {
          this.loggedIn = loggedIn;

          const url = window.location.pathname;
          if (
            loggedIn &&
            url !== '/register' &&
            url !== '/login' &&
            url !== '/forgot-password' &&
            url !== '/verify-email' &&
            url !== '/reset-password'
          ) {
            return userService.get();
          }

          return of(null);
        }),
        takeUntil(this.destroy),
      )
      .subscribe({
        next: (user) => {
          this.user = user;
          this.user$.next(user);
        },
        error: (error: unknown) => {
          console.warn(error);
        },
      });
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.cordova = true;
    }
  }

  protected convertToFormData(value: any, result?: FormData, key?: string) {
    result = result || new FormData();

    if (typeof value === 'function') {
      throw new Error('Cannot serialize functions');
    }

    if (
      (value === null || typeof value !== 'object' || Array.isArray(value)) &&
      !key
    ) {
      throw new Error(
        `Cannot serialize ${
          Array.isArray(value) ? 'array' : typeof value
        } values as root element`,
      );
    }

    if (typeof value === 'undefined') {
      return result;
    }

    if (value === null) {
      result.append(key, '');
      return result;
    }

    if (typeof value === 'boolean') {
      result.append(key, value ? '1' : '0');
      return result;
    }

    if (Array.isArray(value)) {
      value.forEach((item: any, index: number) =>
        this.convertToFormData(item, result, `${key}[${index}]`),
      );
      return result;
    }

    if (typeof value === 'object') {
      if (value instanceof Date) {
        result.append(key, moment(value).toISOString());
        return result;
      }

      if (value instanceof Blob) {
        result.append(key, value, (value as File).name);
        return result;
      }

      Object.keys(value).forEach((item) => {
        if (key) {
          this.convertToFormData(value[item], result, `${key}[${item}]`);
        } else {
          this.convertToFormData(value[item], result, item);
        }
      });
      return result;
    }

    result.append(key, `${value}`);

    return result;
  }

  protected touchControls(control: AbstractControl) {
    control.markAsTouched();

    if (control instanceof UntypedFormArray) {
      control.controls.forEach((child) => this.touchControls(child));
    } else if (control instanceof UntypedFormGroup) {
      Object.keys(control.controls).forEach((name) =>
        this.touchControls(control.controls[name]),
      );
    }
  }

  async showLoader() {
    if (this.isLoading === true) {
      return;
    }
    this.isLoading = true;
    return await this.loadingCtrl
      .create({
        message: this.translate.instant(marker('Refresh.Loading')),
      })
      .then((loader) => {
        loader.present().then(() => {
          if (!this.isLoading) {
            loader.dismiss();
          }
        });
      });
  }

  async hideLoader() {
    this.isLoading = false;
    let topLoader = await this.loadingCtrl.getTop();
    while (topLoader) {
      if (!(await topLoader.dismiss())) {
        console.log('Could not dismiss the topmost loader. Aborting...');
        break;
      }
      topLoader = await this.loadingCtrl.getTop();
    }
  }

  async showToast(message) {
    if (await this.toastController.getTop()) {
      await this.toastController.dismiss();
    }
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom',
    });
    await toast.present();
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  trackByFn(index) {
    return index;
  }

  getTime(post: any) {
    for (const item of post) {
      item.time_by = moment(item.created_at).fromNow();
    }
    return post;
  }

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   *
   * @param b64Data type:String Pure base64 string without contentType
   * @param contentType type:String the content type of the file i.e (image/jpeg - image/png - text/plain)
   * @param sliceSize type:Int SliceSize to process the byteCharacters
   * @return Blob
   */

  b64toBlob(
    b64Data: string,
    contentType: string,
    sliceSize: number = 512,
  ): Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    if (b64Data) {
      b64Data = b64Data.replace(/ /g, '');
    }
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  /**
   * Convert Base64 to blob
   *
   * @param item object of {file: string; name: string}
   * @returns Blob
   */
  getBlobOfImage(item: { file: string; name: string }): Blob {
    let contentType = 'image/jpg';
    // Split the base64 string in data and contentType
    const block = item.file.split(';');
    // Get the content type
    const dataType = block[0].split(':')[1]; // In this case "image/png"
    if (dataType) {
      contentType = dataType;
    }
    let realData = '';
    // get the real base64 content of the file
    if (block[1] === 'charset=utf-8') {
      realData = block[2].split(',')[1]; // In this case "iVBORw0KGg...."
    } else {
      realData = block[1].split(',')[1]; // In this case "iVBORw0KGg...."
    }
    return this.b64toBlob(realData, contentType);
  }

  /**
   * Convertion of object to form data object
   * Appending blob to send to server
   *
   * @param item object that will be converted to formData
   * @return any
   */
  creatingFormDataObject(item: any): any {
    const formData: any = new FormData();
    for (const key in item) {
      if (key !== 'images') {
        if (item[key] === null) {
          formData.append(key, '');
        } else if (item[key] instanceof Array) {
          formData.append(key, JSON.stringify(item[key]));
        } else if (typeof item[key] === 'boolean') {
          formData.append(key, item[key] ? '1' : '0');
        } else if (key) {
          formData.append(key, item[key]);
        }
      }
    }
    return formData;
  }

  /**
   * return boolean to decide weather to show tabs or not
   *
   * @param size number, array length
   * @param flag string, type of data
   * @returns boolean
   */
  showTabsOption(size: number, flag: string, isTabChanged: boolean): boolean {
    if (size === 0 && !flag) {
      return false;
    } else if (size === 0 && flag === 'yes' && isTabChanged) {
      return true;
    } else if (size === 0 && flag === 'yes' && !isTabChanged) {
      return false;
    }
    return true;
  }

  /**
   * Takes object and reutrns object with out null values
   *
   * @param obj any, object from which null keys will be removed
   * @returns any, return object without null keys
   */
  clean(obj: any): any {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  /**
   * It will format the date string and returns the string
   *
   * @param dateTime String of datetime
   * @param format String in which datetime needs to be converted
   * @string returns String of formatted type
   */
  returnFormatedDateTime(dateTime: string, format: string): string {
    return moment(dateTime).format(format);
  }

  cordovaPicture(event: Event, maxUpload = maxImages, loader = false) {
    if (
      (window as any).cordova &&
      (window as any).cordova.platformId !== 'browser'
    ) {
      event.stopPropagation();
      if (this.selectedImages && this.selectedImages.length >= maxUpload) {
        this.showToast(
          this.translate.instant(marker('ValidationMessage.MaxUploadLimit')),
        );
        return;
      } else {
        this.showActionSheet();
      }
    }
  }

  getImages(event: UIEvent, maxUpload = maxImages) {
    this.translate
      .get(['ValidationMessage.MaxUploadLimit', 'ValidationMessage.ValidImage'])
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (messages) => {
          if (this.selectedImages && this.selectedImages.length >= maxUpload) {
            this.showToast(messages['ValidationMessage.MaxUploadLimit']);
            return;
          } else {
            const target = event.target as HTMLInputElement;
            const imageFiles = target.files;
            Array.from(imageFiles).forEach((element) => {
              this.mediaCapture.readFile(element).then((base64data) => {
                if (base64data.indexOf('data:image/') >= 0) {
                  this.setUserImageData(base64data, element.name);
                } else {
                  this.showToast(messages['ValidationMessage.ValidImage']);
                }
              });
            });
          }
        },
        error: (error: unknown) => {
          console.warn(error);
        },
      });
  }

  /**
   * Push image into view and set itgeUploadService.store(signedUrl, blobImage, name, {}).then(uploadData => {
   * this.imagesArray.push(uploadData?.key);
   * cons value
   * @1param data containes base64 image data or path url of file
   * @returns void
   */
  setUserImageData(data: string, name: string): void {
    this.showLoader();
    this.selectedImages.push({ file: data, name });
    const blobImage = this.getBlobOfImage({ file: data, name });
    this.imageUploadService
      .getSignedUrl(blobImage.type)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (signedUrl) => {
          this.imageUploadService
            .store(signedUrl, blobImage, name, {})
            .then(
              (uploadData) => {
                this.imagesArray.push(uploadData?.key);
                this.hideLoader();
              },
              (error) => {
                this.hideLoader();
              },
            )
            .catch((error) => {
              this.hideLoader();
            });
        },
        error: (error: unknown) => this.hideLoader(),
      });
  }

  itemHeightFn(item, index) {
    return 100;
  }

  /**
   * extract data required for map markers
   *
   * @param eventsRec Object of Dates that has EventModel type Array
   * @returns void
   */
  public extractData(
    eventsRec: any,
    filters: MapConfigOptionModel,
    type?: string,
  ): void {
    if (eventsRec instanceof Array) {
      const tempArray = [];
      if (type === 'event') {
        eventsRec.filter((item) => {
          item.forEach((element) => {
            const temp: MapDataModel = {
              date: element.created_at,
              id: element.id,
              title: element.title,
              userId: element.user_id,
              images: element?.images?.length
                ? element?.images[0]?.url
                : 'assets/images/thumb_placeholder_listing.png',
              location: element.location,
              latitude: element.latitude,
              time_to: element.till_date,
              longitude: element.longitude,
              time_from: element.from_date,
              expired_at: element.expired_at,
              surface_area: element.surface_area,
              is_favorited: element.is_favorited,
              surface_area_unit: element.surface_area_unit,
              position: [element.latitude, element.longitude],
            };
            tempArray.push(temp);
          });
        });
      } else if (type === 'marketplace') {
        eventsRec.filter((item) => {
          const temp: MapDataModel = {
            date: item.created_at,
            id: item.id,
            title: item.title,
            userId: item.user_id,
            images: item?.images?.length
              ? item?.images[0]?.url
              : 'assets/images/thumb_placeholder_listing.png',
            location: item?.is_hide_address ? null : item.location,
            latitude: item?.is_hide_address ? null : item.latitude,
            time_to: item.till_date,
            longitude: item?.is_hide_address ? null : item.longitude,
            time_from: item.from_date,
            expired_at: item.expired_at,
            surface_area: item.surface_area,
            is_favorited: item.is_favorited,
            surface_area_unit: item.surface_area_unit,
            position: item?.is_hide_address
              ? [null, null]
              : [item.latitude, item.longitude],
          };
          tempArray.push(temp);
        });
      } else {
        eventsRec.filter((item) => {
          const temp: MapDataModel = {
            date: item.created_at,
            id: item.id,
            title: item.title,
            userId: item.user_id,
            images: item?.images?.length
              ? item?.images[0]?.url
              : 'assets/images/thumb_placeholder_listing.png',
            location: item.location,
            latitude: item.latitude,
            time_to: item.till_date,
            longitude: item.longitude,
            time_from: item.from_date,
            expired_at: item.expired_at,
            surface_area: item.surface_area,
            is_favorited: item.is_favorited,
            surface_area_unit: item.surface_area_unit,
            position: [item.latitude, item.longitude],
          };
          tempArray.push(temp);
        });
      }
      this.extractedData = {
        filters,
        data: [...this.extractedData.data, ...tempArray],
      };
    } else {
      const tempArray = [];
      for (const key of Object.keys(eventsRec)) {
        eventsRec[key].filter((item) => {
          const temp: MapDataModel = {
            date: key,
            id: item.id,
            title: item.title,
            userId: item.user_id,
            images: item.images.length
              ? item.images[0].url
              : 'assets/images/thumb_placeholder_listing.png',
            location: item.location,
            latitude: item.latitude,
            longitude: item.longitude,
            time_to: item.date_time_to,
            categories: item.categories,
            time_from: item.date_time_from,
            is_favorited: item.is_favorited,
            formated_price: item.formated_fee,
            position: [item.latitude, item.longitude],
          };
          tempArray.push(temp);
        });
      }
      this.extractedData = {
        filters,
        data: [...this.extractedData.data, ...tempArray],
      };
    }
  }

  /**
   * Returns ISO2 name
   *
   * @param address of type Address, object returned from google API
   * @returns string
   */
  getISO2Name(address: Address): string | undefined {
    let sn: string;
    for (const item of address.address_components) {
      if (item.types.includes('country')) {
        sn = item.short_name;
      }
    }
    return sn;
  }

  /**
   * Returns formatted array of future plans
   *
   * @param user of type User model
   * @returns array of objects, contains formatted location and university pairs
   */
  getFormattedFuturePlans(user: UserModel): Array<{
    location: any;
    university: string;
    codeIso2: string;
    isArrived: boolean;
  }> {
    // return formatted array
    const formattedArray = [];
    for (let i = 0; i < 3 && i < user?.future_institutes.length; i++) {
      const location = user.future_institutes[i]?.city;
      let university;
      university =
        user.future_institutes.find((pu) => pu?.city?.id === location.id) || '';
      const codeIso2 = user.future_institutes[i]?.city.nationality.code_iso2;
      const isArrived = user.future_institutes[i].is_arrived;
      formattedArray.push({ location, university, codeIso2, isArrived });
    }
    return formattedArray;
  }

  addActive(element: HTMLElement): void {
    if (!element.classList.contains('active')) {
      element.classList.add('active');
    }
  }

  removeActive(element: HTMLElement): void {
    if (element.classList.contains('active')) {
      element.classList.remove('active');
    }
  }

  // ******* Nationalities select box functions ******** */
  /**
   * filter searched data
   * @param nationalities items data to filter
   * @param text search keyword
   */
  filterNationalities(
    nationalities: Nationality[],
    text: string,
    type: string,
  ) {
    return nationalities.filter((item) => {
      return item[type].toLowerCase().indexOf(text) !== -1;
    });
  }

  /**
   * search nationalities async
   * @param event search key event
   * @param type If title is passed in type then search for country "title"
   */
  searchNationalities(
    event: { component: IonicSelectableComponent; text: string },
    type?: string,
  ) {
    const text = event.text.trim().toLowerCase();
    event.component.startSearch();

    // Close any running subscription.
    if (this.nationalitySubscription) {
      this.nationalitySubscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.nationalitySubscription) {
        this.nationalitySubscription.unsubscribe();
      }

      event.component.items = this.nationalities.data;

      // Enable and start infinite scroll from the beginning.
      this.nationalities.meta.current_page++;
      event.component.endSearch();
      event.component.enableInfiniteScroll();
      return;
    }

    this.nationalitySubscription = this.userService
      .getNationalities({
        search: text,
        key: [type || 'nationality'],
        page: '',
        sort: type || 'nationality',
        per_page: NationalityPerPage,
        direction: 'asc',
      })
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (ports) => {
          // Subscription will be closed when unsubscribed manually.
          if (this.nationalitySubscription.closed) {
            return;
          }
          event.component.items = this.filterNationalities(
            ports.data,
            text,
            type,
          );
          event.component.endSearch();
        },
        error: (error: unknown) => {
          console.warn(error);
        },
      });
  }

  /**
   * nationalities infinite scroll handler
   * @param event infinite scroll event
   */
  getMoreNationalities(event: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    const text = (event.text || '').trim().toLowerCase();
    // There're no more ports - disable infinite scroll.
    if (
      this.nationalities.meta.current_page > this.nationalities.meta.last_page
    ) {
      event.component.disableInfiniteScroll();
      return;
    }

    this.userService
      .getNationalities({
        per_page: NationalityPerPage,
        page: this.nationalities.meta.current_page,
        sort: 'title',
        direction: 'asc',
      })
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (items: PaginatedResponse<Nationality>) => {
          setTimeout(() => {
            items.data = event.component.items.concat(items.data);
            this.nationalities.meta.current_page++;
            event.component.items = items.data;
            event.component.endInfiniteScroll();
          }, 2000);
        },
        error: (error: unknown) => {
          console.warn(error);
        },
      });
  }

  /**
   * Check if there are duplicates in the array.
   *
   * @param {Array<any>} arr - The array to check for duplicates
   * @return {boolean} true if duplicates are found, false otherwise
   */
  checkIfDuplicate(arr: Array<any>): boolean {
    return new Set(arr).size !== arr.length;
  }

  public clearMediaInput(event: any): void {
    event.target.value = null;
  }

  /**
   * checking if filters are applied on ads listings
   * @param params contains query params for listings
   * @returns true if filters applied otherwise false
   */
  isFilterApplied(params, radius?: number) {
    let isFiltered = false;
    const defaultParams = [
      'per_page',
      'cities',
      'unit',
      'max_radius',
      'page',
      'other',
      'group_by_date',
      'direction',
      'sort',
      'expired_at',
      'is_future_study_city',
      'latitude',
      'longitude',
      'search',
      'key',
      'facilities',
    ];

    if (params?.hasOwnProperty('max_radius') && params.max_radius !== radius) {
      isFiltered = true;
      return isFiltered;
    }
    // checking if filters are applied or not
    for (const param of Object.keys(params)) {
      const result = defaultParams.indexOf(param);
      if (result === -1) {
        isFiltered = true;
        break;
      }
    }
    return isFiltered;
  }

  /**
   * clear search text field
   * @param selectableRef reference of field
   */
  selectClosed(selectableRef) {
    selectableRef._searchText = '';
  }

  /**
   * reset radio group, if same radio option clicked again
   * @param ref radio group reference
   * @param eventVal click event object
   * @param control binded formControl
   */
  resetRadioGroup(ref, eventVal, control) {
    if (eventVal === ref.value) {
      setTimeout(() => {
        ref.value = null;
        control.reset();
      }, 100);
    }
  }

  openLink(url) {
    if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }
    window.open(url, '_system', 'location=yes');
    return false;
  }

  setPlaceholderImage(ev: any, imgPath: any) {
    const imageElem = ev.srcElement;
    imageElem.src = imgPath;
  }

  findInvalidControls(form: UntypedFormGroup) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  private async showActionSheet() {
    const alert = await this.actionSheetCtrl.create({
      header: this.translate.instant(marker('Camera.Select')),
      backdropDismiss: true,
      buttons: [
        {
          icon: 'close',
          text: this.translate.instant(marker('Buttons.Cancel')),
          role: 'cancel',
        },
        {
          icon: 'camera',
          text: this.translate.instant(marker('Camera.Source.Camera')),
          handler: () => {
            this.mediaCapture
              .getPicture(
                this.camera.PictureSourceType.CAMERA,
                true,
                this.camera.MediaType.PICTURE,
              )
              .then(
                async (result: any) => {
                  if (result) {
                    if (result.file.indexOf('data:image/') >= 0) {
                      await this.hideLoader();
                      this.setUserImageData(result.file, result.fileName);
                    } else {
                      this.showToast(
                        this.translate.instant(
                          marker('ValidationMessage.ValidImage'),
                        ),
                      );
                    }
                  }
                },
                (error) => {
                  this.hideLoader();
                  this.showToast(
                    error ||
                      this.translate.instant(
                        marker('ValidationMessage.NoFileSelected'),
                      ),
                  );
                },
              );
          },
        },
        {
          icon: 'photos',
          text: this.translate.instant(marker('Camera.Source.PhotoLibrary')),
          handler: () => {
            this.mediaCapture
              .getPicture(
                this.camera.PictureSourceType.PHOTOLIBRARY,
                true,
                this.camera.MediaType.PICTURE,
              )
              .then(
                async (result: any) => {
                  if (result) {
                    if (result.file.indexOf('data:image/') >= 0) {
                      await this.hideLoader();
                      this.setUserImageData(result.file, result.fileName);
                    } else {
                      this.showToast(
                        this.translate.instant(
                          marker('ValidationMessage.ImageOnly'),
                        ),
                      );
                    }
                  }
                },
                (error) => {
                  this.hideLoader();
                  this.showToast(
                    error ||
                      this.translate.instant(
                        marker('ValidationMessage.NoFileSelected'),
                      ),
                  );
                },
              );
          },
        },
      ],
    });
    await alert.present();
  }

  translateKey(key: string): string {
    return this.translate.instant(key);
  }
}
