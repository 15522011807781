import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ImagePreviewComponent } from '../components/image-preview/image-preview.component';

@Injectable({
  providedIn: 'root',
})
export class PhotoViewerService {
  constructor(private modalController: ModalController) {}

  async show(imageUrl: string) {
    const modal = await this.modalController.create({
      component: ImagePreviewComponent,
      canDismiss: true,
      componentProps: {
        image: imageUrl,
      },
    });

    await modal.present();
  }
}
