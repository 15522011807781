import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PaginatedResponse } from '../models/paginated-response.model';
import { PostResponseModel } from '../models/post.model';

import { HttpService } from './http.service';

@Injectable()
export class TimeLineService extends HttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  get(params?: any): Observable<PaginatedResponse<PostResponseModel>> {
    return this.http.get<PaginatedResponse<PostResponseModel>>(
      this.buildUrl('timeline/list'),
      {
        params: this.serialize(params),
      },
    );
  }
}
