import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { duration } from 'moment';

@Pipe({
  name: 'calculateAge',
})
export class CalculateAgePipe implements PipeTransform {
  constructor() {}

  transform(value: string) {
    if (value) {
      return Math.floor(duration(moment().diff(moment(value))).asYears());
    }
    return value || '';
  }
}
