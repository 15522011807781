import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private translate: TranslateService,
  ) {}

  formValidationError() {
    return this.showToast(
      this.translate.instant(marker('Errors.Validation.Message')),
    );
  }

  async showToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'bottom',
    });
    await toast.present();
  }

  async showAlert(message) {
    const alert = await this.alertCtrl.create({
      header: message.title,
      message: message.text,
      buttons: [
        {
          text: 'Close',
        },
      ],
    });
    await alert.present();
  }

  handle(error: any) {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case -1:
        case 0:
          this.showToast(this.translate.instant(marker('Errors.Network')));
          return;
        case 401:
          if (typeof error.error === 'object' && error.error.error) {
            this.showToast(error.error.error).catch((warn: unknown) => {
              console.warn(warn);
            });
          } else {
            this.showToast(
              this.translate.instant(marker('Errors.Authentication')),
            );
          }
          return;
        case 403:
          this.showToast(this.translate.instant(marker('Errors.Forbidden')));
          return;
        case 404:
          this.showToast(this.translate.instant(marker('Errors.NotFound')));
          return;
        case 409:
          if (typeof error.error === 'object' && error.error.message) {
            this.showToast(error.error.message);
          } else {
            this.showToast(
              'The request could not be processed because of conflict in the request',
            );
          }
          return;
        case 422:
          let message = this.translate.instant(
            marker('Errors.Validation.Message'),
          );
          if (typeof error.error === 'object' && error.error.errors) {
            const keys = Object.keys(error.error.errors);

            if (keys.length) {
              const errorMessage = Array.isArray(error.error.errors[keys[0]])
                ? error.error.errors[keys[0]][0]
                : error.error.errors[keys[0]];

              message = `<p>${message}</p><em style='white-space: pre-line'>${errorMessage}</em>`;
            }
          }

          return this.showAlert({
            title: this.translate.instant(marker('Errors.Validation.Title')),
            text: message,
          });
          return;
        case 429:
          this.showToast(this.translate.instant(marker('Errors.Throttle')));
          return;
      }

      if (typeof error.error === 'object' && error.error.error) {
        this.showToast(error.error.error);
        return;
      } else {
        this.showToast(error.error);
      }

      this.showToast(this.translate.instant(marker('Errors.Server')));
      return;
    }

    this.showToast(this.translate.instant(marker('Errors.Generic')));
  }
}
