import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { Platform } from '@ionic/angular';

import { HttpService } from './http.service';

declare let window: any;

@Injectable()
export class ImageManagerService extends HttpService {
  folder = 'studinty';
  constructor(
    protected file: File,
    private webView: WebView,
    private platform: Platform,
    http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   *
   * @param b64Data type:String Pure base64 string without contentType
   * @param contentType type:String the content type of the file i.e (image/jpeg - image/png - text/plain)
   * @param sliceSize type:Int SliceSize to process the byteCharacters
   * @return Blob
   */

  b64toBlob(
    b64Data: string,
    contentType: string,
    sliceSize: number = 512,
  ): Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    if (b64Data) {
      b64Data = b64Data.replace(/ /g, '');
    }
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  /**
   * Create a Image file according to its database64 content only.
   *
   * @param folderpath type:String The folder where the file will be created
   * @param filename type:String The name of the file that will be created
   * @param content type:Base64 String Important :
   * The content can't contain the following string (data:image/png[or any other format];base64,). Only the base64 string is expected.
   */
  savebase64AsImageFile(
    folderpath: string,
    filename: string,
    content,
    contentType,
  ) {
    // Convert the base64 string in a Blob
    const DataBlob = this.b64toBlob(content, contentType);
    window.resolveLocalFileSystemURL(folderpath, (dir) => {
      dir.getFile(filename, { create: true }, (file) => {
        file.createWriter(
          (fileWriter) => {
            fileWriter.write(DataBlob);
          },
          () => {
            alert('Unable to save file in path ' + folderpath);
          },
        );
      });
    });
  }

  /**
   * This function will create platform specific directory
   *
   * @returns void
   */
  platformSpecificDirectoryCreator(): void {
    if (this.platform.is('ios')) {
      this.createDirectory(this.file.documentsDirectory);
    } else if (this.platform.is('android')) {
      this.createDirectory(this.file.externalRootDirectory);
    }
  }

  /**
   * It will check/create a new directory based on provided path
   *
   * @param path type: string, it is the path to folder directory
   * @returns void
   */
  createDirectory(path: string): void {
    this.file
      .checkDir(path, this.folder)
      .then((response) => {
        console.log('Directory exists', response);
      })
      .catch((err) => {
        console.log("Directory doesn't exist" + JSON.stringify(err));
        this.file
          .createDir(path, this.folder, false)
          .then((response) => {
            console.log('Directory created', response);
          })
          .catch((error) => {
            console.warn('Directory is not created' + JSON.stringify(error));
          });
      });
  }

  /**
   * retuns folder path based on Platform
   *
   * @returns string
   */
  getFolderPath(): string {
    let path = '';
    if (this.platform.is('ios')) {
      path = this.file.documentsDirectory + this.folder;
    } else if (this.platform.is('android')) {
      path = this.file.externalRootDirectory + this.folder;
    }
    return path;
  }

  /**
   * This will store image to device folder as file
   *
   * @param item object of image array
   * @return string
   */
  async saveImageAsFileInDirectory(item: { file: string; name: string }) {
    let directory: string;
    if (this.platform.is('ios')) {
      directory = this.file.documentsDirectory;
    } else if (this.platform.is('android')) {
      directory = this.file.externalRootDirectory;
    }
    return await this.file
      .checkDir(directory, this.folder)
      .then((res) => this.createFileFromBase64(item))
      .catch(async (err) => {
        return await this.file
          .createDir(directory, this.folder, false)
          .then((response) => {
            console.log('Directory created', response);
            return this.createFileFromBase64(item);
          })
          .catch((error) => {
            console.warn('Directory is not created' + JSON.stringify(error));
          });
      });
  }

  createFileFromBase64(item: { file: string; name: string }) {
    let contentType = 'image/jpg';
    // Split the base64 string in data and contentType
    const block = item.file.split(';');
    // Get the content type
    const dataType = block[0].split(':')[1]; // In this case "image/png"
    if (dataType) {
      contentType = dataType;
    }
    const path = this.getFolderPath();
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "iVBORw0KGg...."
    this.savebase64AsImageFile(path, item.name, realData, contentType);
    // return window.Ionic.WebView.convertFileSrc(this.getFolderPath() + '/' + item.name);
    return this.getFolderPath() + '/' + item.name;
  }

  normalizeUrl(collection: Array<any>): Array<any> {
    return collection.map((item) => {
      for (const image of item.images) {
        if (image.file.indexOf('file:///') >= 0) {
          image.file = this.webView.convertFileSrc(image.file);
        }
      }
      return item;
    });
  }

  /**
   * It will get the file from device storage and sync with server
   *
   * @param selectedFile File local url or Blob to sync with server
   * @return promise {resolve | reject}
   */
  async uploadFileViaFileSystem(
    selectedFile: string | Blob,
    modelId: number,
    modelType: string,
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      if (selectedFile instanceof Blob) {
        const formData = new FormData();
        formData.append('images', selectedFile);
        this.uploadImage(formData, modelType, modelId).subscribe({
          next: (response) => {
            resolve(true);
          },
          error: (error: unknown) => reject(error),
        });
      } else if (typeof selectedFile === 'string') {
        window.resolveLocalFileSystemURL(
          selectedFile,
          (fileEntry) => {
            fileEntry.file(
              (file) => {
                const reader = new window.FileReader();
                reader.onload = (event) => {
                  const blob = new Blob([new Uint8Array(event.target.result)], {
                    type: file.type,
                  });
                  const formData = new FormData();
                  formData.append('images', blob);
                  this.uploadImage(formData, modelType, modelId).subscribe({
                    next: (response) => {
                      resolve(true);
                    },
                    error: (error: unknown) => reject(error),
                  });
                };
                reader.readAsArrayBuffer(file);
              },
              (fileEntryError) => {
                reject(JSON.stringify(fileEntryError));
              },
            );
          },
          (resolveFSError) => {
            reject(JSON.stringify(resolveFSError));
          },
        );
      }
    });
  }

  /**
   * It will upload single image to server
   *
   * @param request Post body contains "images type string"
   * @param model string, name of model
   * @param modelId number, record no of that model
   */
  uploadImage(request: any, model: string, modelId: number) {
    return this.http.post(
      this.buildUrl(`media/${model}/save`, modelId),
      request,
    );
  }
}
