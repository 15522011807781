import { Component, Input } from '@angular/core';
import * as moment from 'moment';

import { EventsModel } from '../../models/events.model';
import { PhotoViewerService } from '../../services/photo-viewer.service';

@Component({
  selector: 'studinty-event-ad-card',
  templateUrl: './event-ad-card.component.html',
  styleUrls: ['./event-ad-card.component.scss'],
})
export class EventAdCardComponent {
  @Input() image: any;
  @Input() noImage: boolean;
  @Input() itemDetail: EventsModel;
  @Input() showImage: boolean = true;

  constructor(private photoViewer: PhotoViewerService) {}

  async previewImage(url: string) {
    if (this.showImage) {
      await this.photoViewer.show(url);
    }
  }

  localTime(item_date) {
    const date = moment(item_date).utc().format('YYYY-MM-DD HH:mm:ss');
    // eslint-disable-next-line import/namespace
    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc).local().format('HH:mm');
    return local;
  }

  localDate(item_date) {
    const date = moment(item_date).utc().format('YYYY-MM-DD HH:mm:ss');
    // eslint-disable-next-line import/namespace
    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    return local;
  }
}
