import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { mapThemeJSON } from '../../constants/general.constant';
import {
  MapConfigOptionModel,
  MapDataModel,
  MapModel,
} from '../../models/map.model';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { EventsService } from '../../services/events.service';
import { HousingService } from '../../services/housing.service';
import { MarketPlaceService } from '../../services/market-place.service';

import createHTMLMapMarker from './marker-utility/marker';

@Component({
  selector: 'studinty-map-component',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnDestroy {
  circles = [];
  loaded = false;
  markersArr = [];
  map: google.maps.Map;
  cacheCoordinates = [];
  cluster: Array<any> = [];
  clusterOptions = {
    styles: {
      color: 'white',
    },
  };
  infoWindow: MapDataModel;
  data: Array<MapDataModel>;
  filters: MapConfigOptionModel;
  zoom = 12; // map zoom level
  @Input() type: string;
  @Input() userId: number;
  @Input() city: Array<number>;
  @Input() routeSegment: string;
  @Output() clusterEventFired = new EventEmitter();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() set mapFullConfig(parameter: MapModel) {
    this.loaded = false;
    this.data = parameter.data;
    this.filters = parameter.filters;
    if (this.map) {
      if (this.checkChanges()) {
        this.ngAfterViewInit();
      } else {
        this.drawMarkers();
      }
    }
  }

  @Output() favEventFired = new EventEmitter();
  @ViewChild('mapRef', { static: false }) mapRef: ElementRef;

  constructor(
    private router: Router,
    public modalCtrl: ModalController,
    public eventService: EventsService,
    public housingService: HousingService,
    public translate: TranslateService,
    public toastController: ToastController,
    public errorHandler: ErrorHandlerService,
    public marketPlaceService: MarketPlaceService,
  ) {}

  /**
   * Intialize map (after view has been initialized, life cycle hook)
   *
   * @returns void
   */
  ngAfterViewInit(): void {
    this.initMap();
    this.drawMarkers();
  }

  /**
   * Intialized map and set default options
   * Intialize markers on map
   * Set info window on map
   *
   * @returns void
   */
  initMap(): void {
    this.cache();
    this.map = new google.maps.Map(this.mapRef.nativeElement, {
      center: new google.maps.LatLng(this.city[0], this.city[1]),
      zoom: this.zoom,
      minZoom: this.zoom,
      maxZoom: this.zoom + 4,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      disableDefaultUI: true,
      clickableIcons: false,
    });
    this.map.setOptions(mapThemeJSON);
  }

  cache() {
    this.cacheCoordinates = [...this.city];
  }

  checkChanges() {
    let i = 0;
    let flag = false;
    for (const item of this.cacheCoordinates) {
      if (item !== this.city[i] && i < 2) {
        flag = true;
      }
      i++;
    }
    return flag;
  }

  drawMarkers() {
    this.clearClusters();
    this.clearMarkers();
    this.clearCircles();
    for (let index = 0; index < this.data.length; index++) {
      const marker = createHTMLMapMarker({
        latlng: new google.maps.LatLng(
          this.data[index].position[0],
          this.data[index].position[1],
        ),
        map: this.map,
        html: `<div class='custom-marker'><div class='custom-marker-inner'><img src='${this.data[index].images}'/></div></div>`,
        id: index,
      });

      marker.addListener('click', () => {
        this.infoWindow = this.data[marker.id];
        this.loaded = true;
      });
      this.markersArr.push(marker);
    }

    this.makeClusters();
    this.drawCircle();
  }

  drawCircle() {
    if (this.filters.radius) {
      const map = this.map;

      const convertedRadius = this.unitConversion();
      let orignalRad = Math.sqrt(convertedRadius);

      for (let i = 1; i < 5; i++) {
        if (i !== 1) {
          orignalRad = orignalRad / 2;
        }
        const cityCircle = new google.maps.Circle({
          map,
          strokeWeight: 2,
          strokeOpacity: i / 10,
          fillColor: '#06294a',
          fillOpacity: i / 10,
          strokeColor: '#06294a',
          radius: orignalRad * 100,
          center: new google.maps.LatLng(this.city[0], this.city[1]),
        });
        this.circles.push(cityCircle);
      }
    }
  }

  makeClusters() {
    // @ts-ignore MarkerClusterer defined via script
    const cluster = new MarkerClusterer(this.map, this.markersArr, {
      imagePath: '../../../assets/images/cluster_images/m',
    });

    // to show listing when map cluster is not expandable
    google.maps.event.addListener(cluster, 'clusterclick', (item) => {
      if (this.map?.getZoom() === 16) {
        // if there are more than one cluster
        if (item?.markers_?.length > 1) {
          const location = {
            lat: item.center_?.lat(),
            lng: item.center_?.lng(),
          };
          this.clusterEventFired.emit(location);
        }
      }
    });
    this.cluster.push(cluster);
  }

  clearClusters() {
    for (const item of this.cluster) {
      item.clearMarkers();
    }
    this.cluster = [];
  }

  clearMarkers() {
    for (const item of this.markersArr) {
      item.setMap(null);
    }
    this.markersArr = [];
  }

  clearCircles() {
    for (const item of this.circles) {
      item.setRadius(0);
      item.setMap(null);
    }
    this.circles = [];
  }

  unitConversion(): number {
    if (this.filters?.unit === 'mi') {
      return this.filters.radius * 0.62137 * 10000;
    }
    return this.filters.radius * 10000;
  }

  toggleFavorite(data: MapDataModel) {
    let service: any;
    let favoriteKey: string;
    let unFavoriteKey: string;
    switch (this.type) {
      case 'EventAd': {
        favoriteKey = marker('EventAd.Success.Favourite');
        unFavoriteKey = marker('EventAd.Success.UnFavourite');
        service = this.eventService;
        break;
      }
      case 'HousingAd': {
        favoriteKey = marker('HousingAd.Success.Favourite');
        unFavoriteKey = marker('HousingAd.Success.UnFavourite');
        service = this.housingService;
        break;
      }
      case 'MarketOffer': {
        favoriteKey = marker('MarketOffer.Success.Favourite');
        unFavoriteKey = marker('MarketOffer.Success.UnFavourite');
        service = this.marketPlaceService;
        break;
      }
    }

    service
      .toggleFavorite(data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          const translateKey = data.is_favorited ? unFavoriteKey : favoriteKey;
          this.showToast(this.translate.instant(translateKey));
          data.is_favorited = !data.is_favorited;
          this.favEventFired.emit(data.id);
        },
        error: (error: unknown) => this.errorHandler.handle(error),
      });
  }

  async showToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom',
    });
    toast.present();
  }

  openItem(item) {
    if (this.type === 'MarketOffer') {
      const route = this.routeSegment
        ? 'product-detail'
        : 'tabs/market-place/detail';
      this.router.navigate([route], {
        queryParams: { item_id: item.id, ref: this.router.url },
      });
    } else if (this.type === 'EventAd') {
      const route = this.routeSegment ? 'event-detail' : 'tabs/events/show';
      this.router.navigate([route], {
        queryParams: { item_id: item.id, ref: this.router.url },
      });
    } else if (this.type === 'HousingAd') {
      const route = this.routeSegment
        ? 'housing-detail'
        : 'tabs/housing/detail';
      this.router.navigate([route], {
        queryParams: { item_id: item.id, ref: this.router.url },
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
