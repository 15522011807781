import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { Platform } from '@ionic/angular';

@Pipe({
  name: 'normalizeUrl',
})
export class NormalizeUrlPipe implements PipeTransform {
  constructor(
    private webView: WebView,
    private platform: Platform,
    private sanitizer: DomSanitizer,
  ) {}

  transform(value: string) {
    if (this.platform.is('ios') || this.platform.is('android')) {
      if (value.indexOf('file:///') >= 0) {
        value = this.webView.convertFileSrc(value);
      }
      if (value && this.platform.is('ios')) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      }
    }
    return value || '';
  }
}
