import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[studintyScrollbarTheme]',
})
export class ScrollbarThemeDirective {
  constructor(el: ElementRef) {
    const stylesheet = `
      ::-webkit-scrollbar {
          width: 0;
          height: 0;
      }
      ::-webkit-scrollbar-track {
          box-shadow: none;
          border-radius: 10px;
          background: transparent;
      }
      ::-webkit-scrollbar-thumb {
          background: rgba(var(--ion-color-tertiary-rgb),0.1);
          border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
          background: var(--ion-color-tertiary);
      }
    `;

    const styleElmt = el.nativeElement.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      el.nativeElement.shadowRoot.appendChild(barStyle);
    }
  }
}
