import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { EventsModel } from '../../models/events.model';
import { UserModel } from '../../models/user.model';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { EventsService } from '../../services/events.service';
import { PhotoViewerService } from '../../services/photo-viewer.service';
import { BaseItem } from '../base-item';

@Component({
  selector: 'studinty-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent extends BaseItem implements OnDestroy {
  @Input() user: UserModel;
  @Input() item: EventsModel;
  @Input() routeSegment: string;
  @Output() itemDeleted: EventEmitter<number> = new EventEmitter();
  @Output() itemRemoved: EventEmitter<number> = new EventEmitter();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private photoViewer: PhotoViewerService,
    loadingCtrl: LoadingController,
    protected translate: TranslateService,
    protected alertController: AlertController,
    toastController: ToastController,
    private eventsService: EventsService,
  ) {
    super(loadingCtrl, translate, toastController);
  }

  /**
   * navigate to item detail page
   * @param item EventsModel
   * @returns void
   */
  gotoItemDetail(item: EventsModel): void {
    const route = this.routeSegment ? 'event-detail' : 'tabs/events/show';
    this.router.navigate([route], {
      queryParams: { item_id: item.id, ref: this.router.url },
    });
  }

  handleToggleFavorite(event: EventsModel) {
    if (this.item.is_favorited && this.item.is_joined) {
      this.optOutEvent();
    } else {
      this.toggleFavorite(event);
    }
  }

  async optOutEvent() {
    const alert = await this.alertController.create({
      header: this.translate.instant('EventAd.OptOut'),
      message: this.translate.instant('EventAd.OptOutConfirmation'),
      buttons: [
        {
          text: this.translate.instant('Buttons.Cancel'),
          role: 'cancel',
        },
        {
          text: this.translate.instant('EventAd.OptOut'),
          handler: () => {
            this.joinOrOptOut();
          },
        },
      ],
    });
    await alert.present();
  }

  /**
   * toggle favourite property of item
   * @param item EventsModel
   * @returns void
   */
  toggleFavorite(item: EventsModel) {
    this.showLoader().then(() => {
      this.eventsService
        .toggleFavorite(item.id)
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (resp) => {
            const message = this.item.is_favorited
              ? marker('EventAd.Success.UnFavourite')
              : marker('EventAd.Success.Favourite');
            this.showToast(this.translate.instant(message));
            item.is_favorited = !item.is_favorited;
            if (!this.item.is_favorited) {
              this.itemRemoved.emit(item.id);
            }
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    });
  }

  joinOrOptOut() {
    this.showLoader().then(() => {
      this.eventsService
        .joinOrOptout(this.item.id)
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (res) => {
            if (!this.item.is_joined) {
              this.item.is_favorited = true;
            }
            const message = this.item.is_joined
              ? marker('EventAd.Success.OptedOut')
              : marker('EventAd.Success.Joined');
            this.showToast(this.translate.instant(message));
            this.item.is_joined = !this.item.is_joined;
            this.item.no_of_participants = this.item.is_joined
              ? ++this.item.no_of_participants
              : --this.item.no_of_participants;
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    });
  }

  /**
   * navigate to item create page
   * @param item EventsModel
   * @param openMode This can be either create or update
   * @returns void
   */
  gotoItemCreate(item = null, openMode: string): void {
    this.router.navigate(
      [
        'tabs/events/add',
        {
          mode: openMode,
        },
      ],
      { state: { item } },
    );
  }

  /**
   * show alert popup to get reason
   * @param itemId id of item
   * @returns void
   */
  async deleteConfirm(itemId: number) {
    const alert = await this.alertController.create({
      header: this.translate.instant(marker('EventAd.Delete.Header')),
      message: this.translate.instant(marker('EventAd.Delete.Message')),
      buttons: [
        {
          text: this.translate.instant(marker('Buttons.Cancel')),
        },
        {
          text: this.translate.instant(marker('Buttons.Delete')),
          handler: (reason) => {
            this.deleteItem(itemId, '');
          },
        },
      ],
    });
    await alert.present();
  }

  /**
   * delete item from storage
   * @param itemId id of item
   * @param reason this is the reason to remove an id (selected option as string)
   * @returns void
   */
  deleteItem(itemId: number, reason: string) {
    this.showLoader().then(() => {
      this.eventsService
        .delete(itemId, { reason })
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (resp) => {
            this.itemDeleted.emit(itemId);
          },
          error: (error: unknown) => {
            this.errorHandler.handle(error);
          },
        });
    });
  }

  async previewImage(url: string) {
    await this.photoViewer.show(url);
  }

  localTime(item_date) {
    const date = moment(item_date).utc().format('YYYY-MM-DD HH:mm:ss');
    // eslint-disable-next-line import/namespace
    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc).local().format('HH:mm');
    return local;
  }

  localDate(item_date) {
    const date = moment(item_date).utc().format('YYYY-MM-DD HH:mm:ss');
    // eslint-disable-next-line import/namespace
    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    return local;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
