import { Component, ErrorHandler, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  AlertController,
  IonRouterOutlet,
  LoadingController,
  MenuController,
  Platform,
} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import OneSignal from 'onesignal-cordova-plugin';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { Config } from './config';
import { studintyAccessToken } from './constants/general.constant';
import { UserModel } from './models/user.model';
import { BasePage } from './pages/base.page';
import { AuthService } from './services/auth.service';
import { GenralService } from './services/genral.service';
import { ImageManagerService } from './services/image-manager.service';
import { NetworkService } from './services/network.service';
import { PushSubscriptionService } from './services/push-subscription.service';
import { PusherService } from './services/pusher.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'studinty-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BasePage implements OnDestroy {
  counter = 0;
  isIntroPages = false;
  private isCordova = false;
  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public appPages = [
    {
      title: marker('MenuItem.Home'),
      url: '/tabs/matching-users',
      icon: '../assets/icon/home-outline.svg',
    },
    {
      title: marker('MenuItem.EditProfile'),
      url: '/profile',
      icon: '../assets/icon/pencil.svg',
    },
    {
      title: marker('MenuItem.MyContacts'),
      url: '/contact',
      icon: '../assets/icon/matching-users2.svg',
    },
    {
      title: marker('MenuItem.ChatRequests'),
      url: '/chat',
      icon: '../assets/icon/chat.svg',
    },
    {
      title: marker('MenuItem.Favorites'),
      url: '/favorites',
      icon: '../assets/icon/star2.svg',
    },
    {
      title: marker('MenuItem.Notifications'),
      url: '/notifications',
      icon: '../assets/icon/bell.svg',
    },
    {
      title: marker('MenuItem.Settings'),
      url: '/settings',
      icon: '../assets/icon/settings.svg',
    },
    {
      title: marker('MenuItem.ChangePassword'),
      url: '/change-password',
      icon: '../assets/icon/change-password.svg',
    },
    {
      title: marker('MenuItem.Legals'),
      url: '/privacy',
      icon: '../assets/icon/privacy-policy.svg',
    },
    {
      title: marker('MenuItem.ContactUs'),
      url: '/contact-us',
      icon: '../assets/icon/contact-us.svg',
    },
  ];

  public introPages = {
    title: marker('MenuItem.Intro.Title'),
    icon: '../assets/icon/intro.svg',
    pages: [
      {
        title: marker('MenuItem.Intro.FellowStudents'),
        url: '/matchings-intro',
        icon: '../assets/icon/information.svg',
      },
      {
        title: marker('MenuItem.Intro.Housing'),
        url: '/housing-intro',
        icon: '../assets/icon/information.svg',
      },
      {
        title: marker('MenuItem.Intro.Events'),
        url: '/events-intro',
        icon: '../assets/icon/information.svg',
      },
      {
        title: marker('MenuItem.Intro.Marketplace'),
        url: '/products-intro',
        icon: '../assets/icon/information.svg',
      },
    ],
  };

  homeUrls = [
    '/tabs/housing',
    '/tabs/events',
    '/tabs/market-place',
    '/tabs/timeline',
  ];

  authUrls = ['/auth/verify', '/auth/forgot', '/auth/register'];

  constructor(
    public userService: UserService,
    authService: AuthService,
    loadingCtrl: LoadingController,
    platform: Platform,
    public imageManager: ImageManagerService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    protected translate: TranslateService,
    public menuCtrl: MenuController,
    public generalService: GenralService,
    private readonly pushSubscriptionService: PushSubscriptionService,
    private storage: Storage,
    private router: Router,
    private errorHandler: ErrorHandler,
    private pusher: PusherService,
    protected alertController: AlertController,
    private orientation: ScreenOrientation,
    private networkService: NetworkService,
  ) {
    super(platform, userService, authService, translate, loadingCtrl);
    this.generalService.getLoggedInUser(this.user$).then((data: UserModel) => {
      if (data?.id) {
        this.pusher.initCounterMecha(data.id);
        this.pusher.initUnreadMessageCount(data.id);
        this.pusher.initUserConnectionCount(data.id);
      }
      this.user = data;
      this.initializeApp();
    });
    this.pusher
      .getNotification()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (counterTotal) => {
          this.generalService.notificationCounter =
            counterTotal.notifications_count;
        },
        error: (error: unknown) => {
          this.errorHandler.handleError(error);
        },
      });
    // to update the unread messages counter on sidebar menu
    this.pusher
      .getUnreadMessagesCountRefresh()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.user.total_unread_messages = res?.total_unread_messages_count;
        },
        error: (error: unknown) => {
          this.errorHandler.handleError(error);
        },
      });
    this.setAppLanguage();
    // update user data in side menu on profile update
    this.userService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user: UserModel) => {
          this.user = user;
        },
        error: (error: unknown) => {
          this.errorHandler.handleError(error);
        },
      });
    // to update the user connections count
    this.pusher
      .getUserConnectionCount()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.user.no_of_connections = res?.no_of_connections;
        },
        error: (error: unknown) => {
          this.errorHandler.handleError(error);
        },
      });
  }

  initializeApp() {
    this.menuCtrl.enable(true);
    this.platform.ready().then(() => {
      this.setUserOnline();

      this.isCordova = this.platform.is('cordova');
      if (this.isCordova) {
        this.orientation.lock(this.orientation.ORIENTATIONS.PORTRAIT);
        this.imageManager.platformSpecificDirectoryCreator();

        this.oneSignalInit();
      }

      if (this.platform.is('ios')) {
        // let status bar overlay webview
        this.statusBar.overlaysWebView(true);
        // set status bar to black
        this.statusBar.backgroundColorByHexString('#06294a');

        document.documentElement.style.setProperty(
          '--status-bar-spacing',
          '20px',
        );
      }
      this.splashScreen.hide();
    });

    this.platform.backButton.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        if (this.homeUrls.includes(this.router.url)) {
          this.router.navigate(['tabs', 'matching-users']);
        } else if (
          (this.routerOutlet && this.routerOutlet.canGoBack()) ||
          this.authUrls.includes(this.router.url)
        ) {
          this.routerOutlet.pop();
        } else {
          this.showExitAlert().then((r) => {
            console.warn(r);
          });
        }
      },
      error: (error: unknown) => {
        this.errorHandler.handleError(error);
      },
    });

    // listener to detect internet availability
    this.networkService.initializeNetworkEvents();
  }

  setUserOnline(): void {
    if (this.user && this.user.id) {
      this.userService
        .onlineUser(this.user.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(); // send user is online call
    }
  }

  setAppLanguage() {
    this.translate.setDefaultLang('en');
    this.translate
      .use('en')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          console.warn('language changed');
        },
        error: (error: unknown) => {
          this.errorHandler.handleError(error);
        },
      });
  }

  logoutAction() {
    this.showLoader();
    const userId = this.user?.id;
    this.generalService
      .sessionEnded()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.logout(userId);
        },
        error: (err: unknown) => {
          this.logout(userId);
        },
      });
  }

  private logout(userId): void {
    this.authService
      .logout()
      .pipe(
        finalize(() => this.hideLoader()),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.loggedIn = false;
          this.storage.remove(studintyAccessToken);
          this.pusher.unBindNotificationCounter(userId);
          this.router.navigate(['auth', 'login'], { replaceUrl: true });
        },
        error: (error: unknown) => this.errorHandler.handleError(error),
      });
  }

  dismiss() {
    this.menuCtrl.close();
  }

  menuClose() {
    this.menuCtrl.close();
    this.isIntroPages = false;
  }

  showIntroPages() {
    this.router.navigate(['matchings-intro']);
    this.isIntroPages = !this.isIntroPages;
  }

  placeHolderImage(ev: any) {
    const imageElem = ev.srcElement;
    imageElem.src = '../assets/icon/img_placeholder_2.svg';
  }

  private oneSignalInit() {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.

    OneSignal.setAppId(Config.oneSignalId);
    OneSignal.setNotificationOpenedHandler((notification) => {
      if (
        notification &&
        notification.notification &&
        notification.notification.additionalData &&
        notification.notification.additionalData['additional']
      ) {
        const navObj = notification.notification.additionalData['additional'];

        const args = {};
        for (const key in navObj.params) {
          if (navObj.params[key] !== null && navObj.params[key] !== undefined) {
            args[key] = navObj.params[key];
          }
        }
        this.router.navigate([navObj.path], {
          queryParams: args,
        });
      }
    });

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      console.log('User accepted notifications: ' + accepted);
    });

    this.authService.onLoginChange.pipe(takeUntil(this.destroy$)).subscribe({
      next: (loggedIn) => {
        OneSignal.getDeviceState((stateChanges) => {
          const unsubscribe =
            !loggedIn || !!(stateChanges && !stateChanges.subscribed);
          const deviceId = stateChanges?.userId ?? null;

          if (deviceId) {
            if (unsubscribe) {
              return this.pushSubscriptionService.unsubscribe(deviceId);
            }

            return this.pushSubscriptionService.setSubscription(deviceId);
          }
        });
      },
      error: (error: unknown) => {
        this.errorHandler.handleError(error);
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async showExitAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant(marker('ExitApp')),
      message: this.translate.instant(marker('ExitConfirm')),
      buttons: [
        {
          text: this.translate.instant(marker('Buttons.Cancel')),
        },
        {
          text: this.translate.instant(marker('Buttons.Ok')),
          handler: () => {
            navigator['app'].exitApp();
          },
        },
      ],
    });
    await alert.present();
  }
}
