import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Config } from '../config';
import {
  CreateMarketPlaceModel,
  CurrencyModel,
  MarketPlaceModel,
} from '../models/market-place.model';
import { PaginatedResponse } from '../models/paginated-response.model';
import { CommentsModel } from '../models/post.model';

import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class MarketPlaceService extends HttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  get(params?: any): Observable<any> {
    return this.http.get<Array<MarketPlaceModel>>(
      `${Config.apiUrl}/marketplace-offer`,
      {
        params: this.serialize(params),
      },
    );
  }

  getFavorites(params?: any): Observable<any> {
    return this.http.get<Array<MarketPlaceModel>>(
      `${Config.apiUrl}/favorite/marketplace-offer-ad`,
      {
        params: this.serialize(params),
      },
    );
  }

  toggleFavorite(id: number): Observable<any> {
    return this.http.post<string>(
      `${Config.apiUrl}/favorite/toggle/marketplace-offer-ad/${id}`,
      {},
    );
  }

  getCurrencies(params?: any): Observable<any> {
    return this.http.get<Array<CurrencyModel>>(`${Config.apiUrl}/currencies`, {
      params: this.serialize(params),
    });
  }

  getCategories(params?: any): Observable<any> {
    return this.http.get<Array<CurrencyModel>>(
      `${Config.apiUrl}/marketplace-offer-categories`,
      {
        params: this.serialize(params),
      },
    );
  }

  create(marketPlace: CreateMarketPlaceModel) {
    return this.http.post(this.buildUrl(`marketplace-offer`), marketPlace);
  }

  update(id: number, marketPlace: CreateMarketPlaceModel) {
    return this.http.put(this.buildUrl(`marketplace-offer/${id}`), marketPlace);
  }

  delete(id: number, params?: any) {
    return this.http.delete(this.buildUrl(`marketplace-offer/${id}`), {
      params: this.serialize(params),
    });
  }

  getDetail(id: number): Observable<MarketPlaceModel> {
    return this.http.get<MarketPlaceModel>(
      this.buildUrl(`marketplace-offer/${id}`),
    );
  }

  fetchMarketplaceComments(eventId: number, params: any) {
    return this.http.get<PaginatedResponse<CommentsModel>>(
      `${Config.apiUrl}/timeline/marketplace-offer-ad/comment/${eventId}/list`,
      {
        params: this.serialize(params),
      },
    );
  }
}
