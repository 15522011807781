import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Config } from '../config';
import {
  CurrencyModel,
  FacilitiesModel,
  HousingModel,
} from '../models/housing.model';
import { PaginatedResponse } from '../models/paginated-response.model';
import { CommentsModel } from '../models/post.model';

import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class HousingService extends HttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  get(params?: any): Observable<any> {
    return this.http.get<Array<HousingModel>>(`${Config.apiUrl}/housing-ad`, {
      params: this.serialize(params),
    });
  }

  getFavorites(params?: any): Observable<any> {
    return this.http.get<Array<HousingModel>>(
      `${Config.apiUrl}/favorite/housing-ad`,
      {
        params: this.serialize(params),
      },
    );
  }

  toggleFavorite(id: number): Observable<any> {
    return this.http.post<string>(
      `${Config.apiUrl}/favorite/toggle/housing-ad/${id}`,
      {},
    );
  }

  getCurrencies(params?: any): Observable<any> {
    return this.http.get<Array<CurrencyModel>>(`${Config.apiUrl}/currencies`, {
      params: this.serialize(params),
    });
  }

  getFacilities() {
    return this.http.get<PaginatedResponse<FacilitiesModel>>(
      this.buildUrl(`housing-ad-facilities`),
    );
  }

  create(housing: any) {
    return this.http.post(this.buildUrl(`housing-ad`), housing);
  }

  update(id: number, housing: any) {
    return this.http.put(this.buildUrl(`housing-ad/${id}`), housing);
  }

  delete(id: number, params?: any) {
    return this.http.delete(this.buildUrl(`housing-ad/${id}`), {
      params: this.serialize(params),
    });
  }

  getDetail(id: number): Observable<HousingModel> {
    return this.http.get<HousingModel>(this.buildUrl(`housing-ad/${id}`));
  }

  fetchHousingComments(eventId: number, params: any) {
    return this.http.get<PaginatedResponse<CommentsModel>>(
      `${Config.apiUrl}/timeline/housing-ad/comment/${eventId}/list`,
      {
        params: this.serialize(params),
      },
    );
  }
}
