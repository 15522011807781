import { Component, Input } from '@angular/core';

import { HousingModel } from '../../models/housing.model';
import { PhotoViewerService } from '../../services/photo-viewer.service';
import { BasePage } from '../../pages/base.page';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { TimeLineModel } from '../../models/post.model';
import { MarketPlaceModel } from '../../models/market-place.model';

@Component({
  selector: 'studinty-product-ad-card',
  templateUrl: './product-ad-card.component.html',
  styleUrls: ['./product-ad-card.component.scss'],
})
export class ProductAdCardComponent extends BasePage {
  @Input() image: any;
  @Input() noImage: boolean;
  @Input() itemDetail: MarketPlaceModel | TimeLineModel;
  @Input() showImage: boolean = true;

  constructor(
    private photoViewer: PhotoViewerService,
    protected translate: TranslateService,
    platform: Platform,
    authService: AuthService,
    userService: UserService,
    loadingCtrl: LoadingController,
    toastController: ToastController,
  ) {
    super(
      platform,
      userService,
      authService,
      translate,
      loadingCtrl,
      toastController,
    );
  }

  async previewImage(url: string) {
    if (this.showImage) {
      await this.photoViewer.show(url);
    }
  }
}
