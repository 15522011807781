import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { EventsCategoriesModel } from '../../models/events.model';
import {
  CurrencyModel,
  MarketPlaceOfferCategoriesModel,
} from '../../models/market-place.model';
import {
  InstitutionModel,
  SelectedCityModel,
  SliderOptions,
  UserModel,
} from '../../models/user.model';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { EventsService } from '../../services/events.service';
import { GenralService } from '../../services/genral.service';
import { MarketPlaceService } from '../../services/market-place.service';
import { UserService } from '../../services/user.service';
import { HousingService } from '../../services/housing.service';
import { FacilitiesModel } from '../../models/housing.model';

@Component({
  selector: 'studinty-filter-listing',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() radius: number;
  @Input() user: UserModel;
  @Input() filterType: string;
  @Input() selectedCity: InstitutionModel;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  value = 5;
  form: UntypedFormGroup;
  minPrice: UntypedFormControl;
  maxPrice: UntypedFormControl;
  currency: UntypedFormControl;
  options = SliderOptions;
  surfaceUnit: UntypedFormControl;
  isCurrencyLoaded = false;
  dateRangeTo: UntypedFormControl;
  dateRangeFrom: UntypedFormControl;
  minSurfaceArea: UntypedFormControl;
  maxSurfaceArea: UntypedFormControl;
  currencies: Array<CurrencyModel>;
  allCategories: Array<EventsCategoriesModel | MarketPlaceOfferCategoriesModel>;
  facilities: FacilitiesModel[] = [];

  units = [
    {
      value: 'km',
      type: 'Kilometers',
    },
    {
      value: 'mi',
      type: 'Miles',
    },
  ];

  min = moment().format('YYYY-MM-DD');
  max = moment().add(10, 'years').format('YYYY');
  housingActionSheetOptions: any = {
    header: 'Please select the common unit of measurement used in this city',
  };
  calendarLoaded: boolean = false;

  constructor(
    private storage: Storage,
    private userService: UserService,
    public modalCtrl: ModalController,
    private translate: TranslateService,
    private eventService: EventsService,
    private genralService: GenralService,
    public generalService: GenralService,
    private errorHandler: ErrorHandlerService,
    private marketPlaceSevice: MarketPlaceService,
    private housingService: HousingService,
  ) {
    this.dateRangeFrom = new UntypedFormControl(
      '',
      Validators.compose([
        (control: AbstractControl) => {
          if (this.dateRangeTo?.value) {
            if (control.value > this.dateRangeTo.value) {
              this.dateRangeTo.setErrors({ smaller: true });
              return { smaller: true };
            } else if (control?.value <= this.dateRangeTo?.value) {
              this.dateRangeTo.setErrors(null);
            }
          }
          return null;
        },
      ]),
    );

    this.dateRangeTo = new UntypedFormControl(
      '',
      Validators.compose([
        (control: AbstractControl) => {
          if (control?.value < this.dateRangeFrom?.value) {
            this.dateRangeFrom.setErrors({ smaller: true });
            return { smaller: true };
          } else if (
            this.dateRangeFrom &&
            this.dateRangeFrom.value &&
            control?.value >= this.dateRangeFrom?.value
          ) {
            this.dateRangeFrom.setErrors(null);
          }
          return null;
        },
      ]),
    );

    this.currency = new UntypedFormControl(
      '',
      Validators.compose([
        (control: AbstractControl) => {
          if (
            (this.minPrice?.valid && this?.minPrice.value) ||
            (this.maxPrice?.valid && this.maxPrice?.value)
          ) {
            if (control && !control.value) {
              return { required: true };
            }
          }
          return null;
        },
      ]),
    );

    this.minPrice = new UntypedFormControl(
      '',
      Validators.compose([
        (control: AbstractControl) => {
          if (typeof this.maxPrice !== 'undefined' && this.maxPrice.value) {
            if (this.maxPrice.valid && control.value > this.maxPrice.value) {
              return { smaller: true };
            }
          }
          if (control.value && this.currency && !this.currency.value) {
            this.currency.setErrors({ required: true });
          } else if (
            this.maxPrice?.value &&
            this.currency &&
            !this.currency.value
          ) {
            this.currency.setErrors({ required: true });
          } else if (this.currency) {
            this.currency.setErrors(null);
          }
          return null;
        },
      ]),
    );

    this.maxPrice = new UntypedFormControl(
      '',
      Validators.compose([
        (control: AbstractControl) => {
          if (
            control.value &&
            this.minPrice.valid &&
            control.value < this.minPrice.value
          ) {
            return { smaller: true };
          }
          if (control.value && this.currency && !this.currency.value) {
            this.currency.setErrors({ required: true });
          } else if (
            this.minPrice?.value &&
            this.currency &&
            !this.currency.value
          ) {
            this.currency.setErrors({ required: true });
          } else if (this.currency) {
            this.currency.setErrors(null);
          }
          return null;
        },
      ]),
    );

    this.minSurfaceArea = new UntypedFormControl(
      '',
      Validators.compose([
        (control: AbstractControl) => {
          if (
            typeof this.maxSurfaceArea !== 'undefined' &&
            this.maxSurfaceArea.value
          ) {
            if (
              this.maxSurfaceArea.valid &&
              control.value > this.maxSurfaceArea.value
            ) {
              return { smaller: true };
            }
          }
          if (control.value && this.surfaceUnit && !this.surfaceUnit.value) {
            this.surfaceUnit.setErrors({ required: true });
          } else if (
            this.maxSurfaceArea?.value &&
            this.surfaceUnit &&
            !this.surfaceUnit.value
          ) {
            this.surfaceUnit.setErrors({ required: true });
          } else if (this.surfaceUnit) {
            this.surfaceUnit.setErrors(null);
          }
          return null;
        },
      ]),
    );

    this.maxSurfaceArea = new UntypedFormControl(
      '',
      Validators.compose([
        (control: AbstractControl) => {
          if (
            typeof this.minSurfaceArea !== 'undefined' &&
            this.minSurfaceArea.value
          ) {
            if (
              this.minSurfaceArea.valid &&
              control.value < this.minSurfaceArea.value
            ) {
              return { greater: true };
            }
          }
          if (control.value && this.surfaceUnit && !this.surfaceUnit.value) {
            this.surfaceUnit.setErrors({ required: true });
          } else if (
            this.minSurfaceArea?.value &&
            this.surfaceUnit &&
            !this.surfaceUnit.value
          ) {
            this.surfaceUnit.setErrors({ required: true });
          } else if (this.surfaceUnit) {
            this.surfaceUnit.setErrors(null);
          }
          return null;
        },
      ]),
    );

    this.surfaceUnit = new UntypedFormControl(
      '',
      Validators.compose([
        (control: AbstractControl) => {
          if (
            control.value &&
            this.minSurfaceArea &&
            !this.minSurfaceArea.value
          ) {
            this.minSurfaceArea.setErrors({ required: true });
          } else {
            this.minSurfaceArea.setErrors(null);
          }
          if (
            control.value &&
            this.maxSurfaceArea &&
            !this.maxSurfaceArea.value
          ) {
            this.maxSurfaceArea.setErrors({ required: true });
          } else {
            this.maxSurfaceArea.setErrors(null);
          }
          if (
            (this.minSurfaceArea?.valid && this?.minSurfaceArea.value) ||
            (this.maxSurfaceArea?.valid && this.maxSurfaceArea?.value)
          ) {
            if (control && !control.value) {
              return { required: true };
            }
          }
          return null;
        },
      ]),
    );

    this.form = new UntypedFormGroup({
      min_price: this.minPrice,
      max_price: this.maxPrice,
      unit: new UntypedFormControl('km'),
      cities: new UntypedFormControl([]),
      latitude: new UntypedFormControl(''),
      longitude: new UntypedFormControl(''),
      daterange_to: this.dateRangeTo,
      currency_id: this.currency,
      max_radius: new UntypedFormControl(null),
      categories: new UntypedFormControl(null),
      date_range: new UntypedFormControl(null),
      current_city: new UntypedFormControl(''),
      daterange_from: this.dateRangeFrom,
      formatted_address: new UntypedFormControl(''),
      min_surface_area: this.minSurfaceArea,
      max_surface_area: this.maxSurfaceArea,
      surface_area_unit: this.surfaceUnit,
      search: new UntypedFormControl(''),
      facilities: new UntypedFormArray([]),
    });

    this.facilities.forEach((facility) => {
      const control = this.form.get('facilities') as FormArray;
      control.push(
        new UntypedFormGroup({
          id: new UntypedFormControl(facility?.id),
          value: new UntypedFormControl(null),
        }),
      );
    });
  }

  ngOnInit() {
    // switch (this.filterType) {
    //   case 'event':
    //     this.getEventCategories();
    //     break;
    //   case 'marketplace-offer':
    //     this.getMarketplaceOfferCategories();
    //     break;
    //   default:
    //     break;
    // }
    this.getData();
  }

  ionViewWillLeave() {
    this.calendarLoaded = false;
  }

  initializeFilters() {
    this.storage
      .get(`filter-${this.filterType}-${this.selectedCity?.city?.name}`)
      .then((filterData) => {
        if (filterData) {
          this.data = filterData;
        }
        let currencyFilter: Array<any>;
        if (typeof this.data?.currency_id === 'number') {
          currencyFilter = this.currencies.filter(
            (item) => item.id === this.data.currency_id && item,
          );
        } else {
          currencyFilter = [this.data?.currency_id];
        }

        this.form.patchValue({
          min_price:
            this.data && this.data.min_price ? this.data.min_price : null,
          max_price:
            this.data && this.data.max_price ? this.data.max_price : null,
          unit: this.data && this.data.unit ? this.data.unit : 'km',
          latitude: this.data && this.data.latitude ? this.data.latitude : null,
          longitude:
            this.data && this.data.longitude ? this.data.longitude : null,
          min_surface_area:
            this.data && this.data.min_surface_area
              ? this.data.min_surface_area
              : null,
          max_surface_area:
            this.data && this.data.max_surface_area
              ? this.data.max_surface_area
              : null,
          surface_area_unit:
            this.data && this.data.surface_area_unit
              ? this.data.surface_area_unit
              : null,
          max_radius:
            this.data && this.data?.max_radius
              ? this.data.max_radius
              : this.radius,
          daterange_to:
            this.data && this.data.daterange_to ? this.data.daterange_to : null,
          categories: this.data?.categories || null,
          currency_id:
            this.data && this.data.currency_id
              ? currencyFilter[0]
              : this.selectedCity?.city?.nationality?.currency,
          date_range: null,
          daterange_from:
            this.data && this.data.daterange_from
              ? this.data.daterange_from
              : null,
          formatted_address:
            this.data && this.data.formatted_address
              ? this.data.formatted_address
              : null,
          cities:
            this.data && this.data.cities
              ? this.data.cities
              : [this.selectedCity?.city?.id],
          current_city: this.data?.current_city?.name,
          search: this.data?.search,
        });

        this.facilities.forEach((facility) => {
          const control = this.form.get('facilities') as FormArray;
          control.push(
            new UntypedFormGroup({
              id: new UntypedFormControl(facility?.id),
              value: new UntypedFormControl(
                this.data?.facilities.find((f) => f.id === facility.id)
                  ?.value || null,
              ),
            }),
          );
        });
      });

    // TODO:: this is a dirty hack, needs cleaner implementation
    setTimeout(() => {
      this.calendarLoaded = true;
    }, 1000);
  }

  getData() {
    this.generalService.showLoader();
    const serviceMethods = [
      this.genralService.getCurrencies({
        per_page: -1,
        direction: 'asc',
        sort: 'name',
      }),
      this.housingService.getFacilities(),
    ];
    if (this.filterType === 'event') {
      serviceMethods.push(
        this.eventService.getCategories({
          per_page: 100,
          direction: 'asc',
          sort: 'title',
        }),
      );
    } else if (this.filterType === 'marketplace-offer') {
      serviceMethods.push(
        this.marketPlaceSevice.getCategories({
          per_page: 100,
          direction: 'asc',
          sort: 'title',
        }),
      );
    }
    forkJoin(serviceMethods)
      .pipe(
        finalize(() => this.generalService.hideLoader()),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.facilities = data[1]?.data;
          this.currencies = data[0];
          this.allCategories = data[2]?.data;
          this.isCurrencyLoaded = true;
          this.initializeFilters();
        },
        error: (error: unknown) => {
          this.errorHandler.handle(error);
        },
      });
  }

  getEventCategories() {
    this.eventService
      .getCategories({ per_page: 100, direction: 'asc', sort: 'title' })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (categories) => {
          this.allCategories = categories.data;
          this.form.patchValue({
            categories:
              this.data && this.data.categories ? this.data.categories : null,
          });
        },
        error: (error: unknown) => {
          this.errorHandler.handle(error);
        },
      });
  }

  getMarketplaceOfferCategories() {
    this.marketPlaceSevice
      .getCategories({ per_page: 100, direction: 'asc', sort: 'title' })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (categories) => {
          this.allCategories = categories.data;
          this.form.patchValue({
            categories:
              this.data && this.data.categories ? this.data.categories : null,
          });
        },
        error: (error: unknown) => {
          this.errorHandler.handle(error);
        },
      });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  modifyPayload() {
    const range: any = {};

    if (this.form.controls.daterange_from.value) {
      range.from = moment(this.form.controls.daterange_from.value).format(
        'YYYY-MM-DD',
      );
    }

    if (this.form.controls.daterange_to.value) {
      range.to = moment(this.form.controls.daterange_to.value).format(
        'YYYY-MM-DD',
      );
    }

    this.form.patchValue({
      date_range: range,
    });

    if (this.form.value.min_price && this.form.value.max_price) {
      this.form.value.price =
        this.form.value.min_price + '|' + this.form.value.max_price;
    } else if (this.form.value.min_price) {
      this.form.value.price = this.form.value.min_price;
    } else if (this.form.value.max_price) {
      this.form.value.price = 0 + '|' + this.form.value.max_price;
    }
  }

  /**
   * Form submission
   */
  performFilter() {
    this.modifyPayload();
    Object.keys(this.form.controls).forEach((key) =>
      this.form.get(key).markAsTouched(),
    );

    if (this.form.invalid) {
      this.errorHandler.formValidationError();
      return;
    }
    this.storage.set(
      `filter-${this.filterType}-${this.selectedCity?.city?.name}`,
      this.form.value,
    );
    this.modalCtrl.dismiss(this.form.value);
  }

  /**
   * It will handle the Location related fields
   * @param address formatted address returned from google
   */
  handleAddressChange(address: Address) {
    const cityVal = this.generalService.getComponentByType(address, 'locality');
    this.form.patchValue({
      current_city:
        cityVal && cityVal.long_name
          ? cityVal.long_name
          : address.formatted_address,
    });
    this.form.patchValue({
      formatted_address: address.formatted_address,
      latitude: address.geometry.location.lat(),
      longitude: address.geometry.location.lng(),
    });
  }

  /**
   * Get current location
   */
  getCurrentLocation() {
    this.genralService.showLoader();
    this.genralService.checkPermission((res) => {
      if (res) {
        if (this.genralService.cordova) {
          this.genralService
            .gpsStatus()
            .then((status) => {
              this.genralService.hideLoader();
              if (status) {
                this.patchLocationValues();
              }
            })
            .catch((error) => this.genralService.hideLoader());
        } else {
          this.genralService.hideLoader();
          this.patchLocationValues();
        }
      }
    });
  }

  /**
   * get location lat, lng using geolocation service
   * and set form fields
   */
  patchLocationValues() {
    this.userService.getCurrentLocation((location) => {
      this.updateCurrentCity(
        location.coords.latitude,
        location.coords.longitude,
      );
      this.form.patchValue({
        formatted_address:
          location?.location?.subLocality ||
          this.translate.instant(marker('NearMe')),
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      });
    });
  }

  clearFilters() {
    this.form.reset({
      cities: this.selectedCity ? this.selectedCity?.city?.id : '',
      currency_id: '',
      max_radius: this.radius,
    });
    this.performFilter();
  }

  /**
   * get city from lat lng
   * @param lat latitude value
   * @param lng longitue value
   */
  updateCurrentCity(lat, lng) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latlng }, (results: any, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const cityVal = this.generalService.getComponentByType(
          results[1],
          'locality',
        );
        this.form.patchValue({
          current_city:
            cityVal && cityVal.long_name
              ? cityVal.long_name
              : results[1].formatted_address,
        });
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  temp() {
    console.log(this.form.value);
  }
}
