import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HousingModel } from '../../models/housing.model';
import { UserModel } from '../../models/user.model';

@Component({
  selector: 'studinty-favorite-housing',
  templateUrl: './favorite-housing.component.html',
  styleUrls: ['./favorite-housing.component.scss'],
})
export class FavoriteHousingComponent {
  @Input() user: UserModel;
  @Input() isEmpty$: Observable<boolean>;
  @Input() housings$: Observable<Array<HousingModel>>;
  @Output()  refresh : EventEmitter<string> = new EventEmitter();

  constructor(protected translate: TranslateService) {}

  itemRemovedHandler(itemId: number) {
    this.refresh.emit('housing');
  }
}
