import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Config } from '../config';
import { PendingRequestModel } from '../models/chat-request.model';
import {
  AdResponseModel,
  ChatActionModel,
  ChatMessagesModel,
  ChatPayloadModel,
  ChatRequestModel,
  ContactResponseModel,
  ConversationModel,
} from '../models/chat.model';
import { PaginatedResponse } from '../models/paginated-response.model';

import { HttpService } from './http.service';

@Injectable()
export class ChatService extends HttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  get(
    userId: number,
    section: string,
    params?: any,
  ): Observable<PaginatedResponse<ContactResponseModel | AdResponseModel>> {
    return this.http.get<PaginatedResponse<ContactResponseModel>>(
      `${Config.apiUrl}/chat/${section}/request/list/${userId}`,
      {
        params: this.serialize(params),
      },
    );
  }

  getChatRequests(requestType: string, userId: number, params?: any) {
    return this.http.get<PaginatedResponse<PendingRequestModel>>(
      this.buildUrl(`chat/${requestType}/request/pending/list/${userId}`),
      {
        params: this.serialize(params),
      },
    );
  }

  delete(chatId: number) {
    return this.http.delete(this.buildUrl(`chat/${chatId}/delete`));
  }

  removeChat(chatId: number) {
    return this.http.delete(this.buildUrl(`chat/history/${chatId}/delete`));
  }

  unfriend(chatId: number) {
    return this.http.get(this.buildUrl(`chat/${chatId}/unfriend`));
  }

  getConversation(params?: any) {
    return this.http.get<PaginatedResponse<ConversationModel>>(
      this.buildUrl(`chat/${params.match_id}/message`),
      {
        params: this.serialize(params),
      },
    );
  }

  getChatUser(chatId: string) {
    return this.http.get(this.buildUrl(`chat/${chatId}`));
  }

  getActivity(params?: any) {
    return this.http.get<PaginatedResponse<ChatMessagesModel>>(
      this.buildUrl('accepted-activity-matches'),
      {
        params: this.serialize(params),
      },
    );
  }

  sendMessage(request: ChatPayloadModel) {
    return this.http.post(
      this.buildUrl(`chat/${request.match_id}/message`),
      request,
    );
  }

  blockUser(request: ChatActionModel) {
    return this.http.post(this.buildUrl('accept-or-block'), request);
  }

  messagesRead(messageId: number) {
    return this.http.get(this.buildUrl(`chat-message/${messageId}`));
  }

  changeStatus(chatId: string, body: { status: string }) {
    return this.http.post(this.buildUrl(`chat/request/status/${chatId}`), body);
  }

  blockUnknownUser(userId: number) {
    return this.http.post(
      this.buildUrl(`chat/block-unknown-users/${userId}`),
      {},
    );
  }

  /**
   * Remove user contact
   *
   * @param id number, to remove user contact permanently
   */
  contactRemoval(id: number) {
    return this.http.delete(this.buildUrl(`chat/${id}/delete`));
  }

  /**
   * Send chat request
   *
   * @param userId number, to send chat request
   */
  sendRequest(userId: number) {
    return this.http.post(this.buildUrl(`chat/request/${userId}`), {});
  }

  /**
   * send chat request from model directly
   *
   * @param id number, id of model
   * @param model string, name of the model
   * @param message string, message for chat request
   */
  sendChatRequestFromAd(id: number, model: string, message?: string) {
    return this.http.post(this.buildUrl(`chat/${model}/request/${id}`), {
      message,
    });
  }

  /**
   * send chat request from profile directly
   *
   * @param id number, id of user
   */
  sendChatRequestFromUser(id: number, message: string) {
    return this.http.post(this.buildUrl(`chat/user/request/${id}`), {
      message,
    });
  }

  getBlockedUsers(params) {
    return this.http.get<PaginatedResponse<ChatRequestModel>>(
      `${Config.apiUrl}/chat/request/blocked`,
      {
        params: this.serialize(params),
      },
    );
  }
}
