import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ErrorHandlerService } from '../../services/error-handler.service';
import { GenralService } from '../../services/genral.service';

@Component({
  selector: 'studinty-report-ad',
  templateUrl: './report-ad.component.html',
  styleUrls: ['./report-ad.component.scss'],
})
export class ReportAdComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  @Input() itemId: number;
  @Input() adType: string;
  isLoaded = false;
  reportReasons: Array<any>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  customActionSheetOptions = {
    header: 'Select Reason',
    cssClass: 'report-ad-alert',
  };

  constructor(
    public generalService: GenralService,
    private errorHandler: ErrorHandlerService,
    public modalCtrl: ModalController,
    protected translate: TranslateService,
  ) {
    this.form = new UntypedFormGroup({
      reported_reason_id: new UntypedFormControl(
        '',
        Validators.compose([Validators.required]),
      ),
      message: new UntypedFormControl(
        '',
        Validators.compose([Validators.required]),
      ),
    });
  }

  ngOnInit() {
    this.getReportReasons();
  }

  getReportReasons() {
    this.generalService
      .getReportReasons()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (reasons) => {
          this.reportReasons = reasons;
          this.isLoaded = true;
        },
        error: (error: unknown) => {
          this.errorHandler.handle(error);
        },
      });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  /**
   * Form submission
   */
  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.errorHandler.formValidationError();
      return;
    }

    const key = marker('Report.ReportAd.Success');

    if (this.adType === 'comment') {
      this.generalService
        .reportComment(this.itemId, this.adType, this.form.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.generalService.showToast(this.translate.instant(key));
            this.dismiss();
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    } else {
      this.generalService
        .reportAd(this.itemId, this.adType, this.form.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.generalService.showToast(this.translate.instant(key));
            this.dismiss();
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
