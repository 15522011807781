import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  publishReplay,
  refCount,
} from 'rxjs/operators';

import { studintyAccessToken } from '../constants/general.constant';
import { TokenResponseModel } from '../models/auth.model';

@Injectable()
export class TokenStoreService {
  private readonly tokenObservable: Observable<TokenResponseModel>;

  private tokenSubject: ReplaySubject<TokenResponseModel> = new ReplaySubject(
    1,
  );

  constructor(private storage: Storage) {
    storage
      .get(studintyAccessToken)
      .catch(() => null)
      .then((token) => {
        this.tokenSubject.next(token);
      });

    this.tokenObservable = this.tokenSubject.pipe(
      distinctUntilChanged(),
      publishReplay(1),
      refCount(),
    );
  }

  get loggedIn() {
    return this.onLoginChange.pipe(first());
  }

  get onLoginChange() {
    return this.onTokenChange.pipe(
      map((token) => {
        return !!token;
      }),
    );
  }

  get onTokenChange() {
    return this.tokenObservable;
  }

  get token() {
    return this.onTokenChange.pipe(first());
  }

  updateToken(value?: TokenResponseModel) {
    let promise: Promise<any>;

    if (value) {
      promise = this.storage.set(studintyAccessToken, value);
    } else {
      promise = this.storage.remove(studintyAccessToken).catch(() => null);
    }

    return promise.then(() => {
      this.tokenSubject.next(value);

      return value;
    });
  }
}
