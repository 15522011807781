export const environment = {
  production: false,
  siteUrl: 'https://studinty.demo3.appelit.com',
  apiUrl: 'https://studinty.demo3.appelit.com/api',
  baseUrl: 'https://studinty.demo3.appelit.com/api',
  // siteUrl: 'http://10.28.86.85:8000',
  // apiUrl: 'http://10.28.86.85:8000/api',
  // baseUrl: 'http://10.28.86.85:8000/api',
  oneSignalId: 'a2e26733-e6c8-4857-b5ee-caaf0deb19c3',
  pusher: {
    key: '0eff77594ddf306b339d',
    cluster: 'ap2',
  },
  fakeLocation: false,
  fakeLocationData: {
    coords: {
      latitude: 52.128664,
      longitude: 4.333458,
    },
  },
  facebookAppId: '706601636883150',
};
