import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';

export enum ConnectionStatus {
  Online,
  Offline,
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  appPaused = false;
  connectionDisconnect = false;
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(
    ConnectionStatus.Offline,
  );

  constructor(
    private plt: Platform,
    private router: Router,
    private network: Network,
    private toastController: ToastController,
    private translate: TranslateService,
    private errorService: ErrorHandlerService,
  ) {
    this.plt.ready().then(() => {
      this.initializeNetworkEvents();
      const status =
        this.network.type !== 'none'
          ? ConnectionStatus.Online
          : ConnectionStatus.Offline;
      this.status.next(status);
    });
  }

  public initializeNetworkEvents() {
    if (this.plt.is('android') || this.plt.is('ios')) {
      this.plt.pause.subscribe({
        next: () => {
          this.appPaused = true;
        },
        error: (error: unknown) => {
          this.errorService.handle(error);
        },
      });

      this.network.onDisconnect().subscribe({
        next: () => {
          if (this.connectionDisconnect) {
            return;
          }
          this.connectionDisconnect = true;
          this.router.navigate(['network-issue'], { replaceUrl: true });

          this.manageStatus();
        },
        error: (error: unknown) => {
          this.errorService.handle(error);
        },
      });

      this.network.onConnect().subscribe({
        next: () => {
          if (!this.appPaused || this.connectionDisconnect) {
            this.successFn();
          }
        },
        error: (error: unknown) => {
          this.errorService.handle(error);
        },
      });
    }
  }

  private successFn() {
    this.connectionDisconnect = false;
    this.router.navigate(['auth', 'login'], { replaceUrl: true });

    this.status.subscribe({
      next: (res) => {
        if (res === ConnectionStatus.Offline) {
          this.updateNetworkStatus(ConnectionStatus.Online);
        }
      },
      error: (error: unknown) => {
        this.errorService.handle(error);
      },
    });
  }

  private updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);

    const connection =
      status === ConnectionStatus.Offline
        ? this.translate.instant(marker('OfflineMode'))
        : this.translate.instant(marker('OnlineMode'));
    const toastCtrl = this.toastController.create({
      message: connection,
      duration: 2000,
      position: 'bottom',
    });
    toastCtrl.then((toast) => toast.present());
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  private manageStatus() {
    this.status.subscribe({
      next: (res) => {
        if (res === ConnectionStatus.Online) {
          this.updateNetworkStatus(ConnectionStatus.Offline);
        }
      },
      error: (error: unknown) => {
        this.errorService.handle(error);
      },
    });
  }
}
