import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EventsModel } from '../../models/events.model';
import { UserModel } from '../../models/user.model';

@Component({
  selector: 'studinty-favorite-events',
  templateUrl: './favorite-events.component.html',
  styleUrls: ['./favorite-events.component.scss'],
})
export class FavoriteEventsComponent {
  isLoaded = false;

  @Input() user: UserModel;
  @Input() isEmpty$: Observable<boolean>;
  @Input() events$: Observable<Array<EventsModel>>;
  @Output()  refresh : EventEmitter<string> = new EventEmitter();

  constructor(protected translate: TranslateService) {}

  itemRemovedHandler(itemId: number) {
    this.refresh.emit('events');
  }
}
