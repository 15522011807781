import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { Observable, Subject } from 'rxjs';

import { Config } from '../config';
import { ChatResponseModel } from '../models/chat.model';

import { ErrorHandlerService } from './error-handler.service';
import { HttpService } from './http.service';
import { NotificationService } from './notification.service';
import { TokenStoreService } from './token-store.service';

@Injectable({
  providedIn: 'root',
})
export class PusherService extends HttpService {
  channel: any;
  actionChannel: any;
  refreshChannel: any;
  userStatusChannel: any;
  notificationChannel: any;
  unreadMessagesChannel: any;
  userConnectionChannel: any;

  private subject: Subject<any> = new Subject<any>();
  private refresh: Subject<any> = new Subject<any>();
  private userStatus: Subject<any> = new Subject<any>();
  private notification: Subject<any> = new Subject<any>();
  private unReadMessagesCount: Subject<any> = new Subject<any>();
  private userConnectionCount: Subject<any> = new Subject<any>();

  private pusherClient: any;

  constructor(
    http: HttpClient,
    private tokenService: TokenStoreService,
    private errorHandler: ErrorHandlerService,
    private notificationService: NotificationService,
  ) {
    super(http);
    this.initializePusher();
  }

  /**
   * initialize pusher service
   * create instance of pusher that handles the functionality related to pusher
   */
  initializePusher() {
    this.tokenService.token.subscribe({
      next: (token) => {
        if (token && token.access_token) {
          this.pusherClient = new Pusher(Config.pusher.key, {
            cluster: Config.pusher.cluster,
            authEndpoint: this.buildUrl('broadcasting/auth'),
            forceTLS: true,
            authTransport: 'ajax',
            auth: {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token.access_token}`,
              },
              params: null,
            },
          });
        }
      },
      error: (error: unknown) => {
        this.errorHandler.handle(error);
      },
    });
  }

  /**
   * will return subject as observable to subscribe to it
   * and in its subscription will receive new messages
   */
  getMessages(): Observable<any> {
    return this.subject.asObservable();
  }

  getUserStatus(): Observable<any> {
    return this.userStatus.asObservable();
  }

  getRefresh(): Observable<any> {
    return this.refresh.asObservable();
  }

  getNotification(): Observable<any> {
    return this.notification.asObservable();
  }

  getUnreadMessagesCountRefresh(): Observable<any> {
    return this.unReadMessagesCount.asObservable();
  }

  getUserConnectionCount(): Observable<any> {
    return this.userConnectionCount.asObservable();
  }

  /**
   * Will establish connection for receiving messages
   * @param userId user id , it is being used here as channel name
   */
  initChat(chatId: string) {
    this.channel = this.pusherClient.subscribe('presence-chat.' + chatId);
    this.channel.bind(
      'message.received',
      (data: ChatResponseModel) => {
        this.subject.next(data);
      },
      (error) => {
        this.errorHandler.handle(error);
      },
    );
  }

  /**
   * Unbind chat event
   * @param userId user id , it is being used here as channel name
   */
  unBindChatEvent(chatId: number) {
    this.channel.unbind('message.received');
    this.pusherClient.unsubscribe('presence-chat.' + chatId);
  }

  /**
   * Will establish connection for receiving messages
   * @param userId user id , it is being used here as channel name
   */
  refreshChat(userId: number) {
    this.refreshChannel = this.pusherClient.subscribe(
      'private-chat.status.' + userId,
    );
    this.refreshChannel.bind(
      'unread.message',
      (data: ChatResponseModel) => {
        this.refresh.next(data);
      },
      (error) => {
        this.errorHandler.handle(error);
      },
    );
  }

  /**
   * Unbind refresh event
   * @param userId user id , it is being used here as channel name
   */
  unBindRefreshEvent(userId: number) {
    this.refreshChannel.unbind('unread.message');
    this.pusherClient.unsubscribe('private-chat.status.' + userId);
  }

  /**
   * Will establish connection for receiving notifications counter
   * @param userId user id , it is being used here as channel name
   */
  initNotificationCounter(userId: number) {
    if (userId) {
      this.notificationChannel = this.pusherClient.subscribe(
        'private-user.notification.' + userId,
      );
      this.notificationChannel.bind(
        'notifications.count',
        (data) => {
          this.notification.next(data);
        },
        (error) => {
          this.errorHandler.handle(error);
        },
      );
    }
  }

  initUserConnectionCount(userId: number) {
    if (userId) {
      this.userConnectionChannel = this.pusherClient.subscribe(
        'private-user.connections.' + userId,
      );
      this.userConnectionChannel.bind(
        'user.connections',
        (data) => {
          this.userConnectionCount.next(data);
        },
        (error) => {
          this.errorHandler.handle(error);
        },
      );
    }
  }

  initUnreadMessageCount(userId: number) {
    if (userId) {
      this.unreadMessagesChannel = this.pusherClient.subscribe(
        'private-messages.unread.' + userId,
      );
      this.unreadMessagesChannel.bind(
        'total.unread.messages',
        (data) => {
          this.unReadMessagesCount.next(data);
        },
        (error) => {
          this.errorHandler.handle(error);
        },
      );
    }
  }

  /**
   * Unbind notifications event
   * @param userId user id , it is being used here as channel name
   */
  unBindNotificationCounter(userId: number) {
    if (userId) {
      if (this.notificationChannel) {
        this.notificationChannel.unbind('notifications.count');
      }
      this.pusherClient.unsubscribe('private-user.notification.' + userId);
    }
  }

  initUserOnlineStatus() {
    this.userStatusChannel = this.pusherClient.subscribe('user-status');
    this.userStatusChannel.bind(
      'user.status',
      (data) => {
        this.userStatus.next(data);
      },
      (error) => {
        this.errorHandler.handle(error);
      },
    );
  }

  setCounter() {
    this.notification.next({ notifications_count: 0 });
  }

  getCounterFromAPI() {
    this.notificationService.getNotificationCounter().subscribe({
      next: (item: unknown) => {
        this.notification.next(item);
      },
      error: (error: unknown) => {
        this.errorHandler.handle(error);
      },
    });
  }

  initCounterMecha(userId: number) {
    this.getCounterFromAPI();
    this.initNotificationCounter(userId);
  }
}
