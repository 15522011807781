import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  AlertController,
  IonTextarea,
  LoadingController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import {
  desc,
  EventAd,
  HousingAd,
  Post,
  PostAd,
  ProductAd,
  Timeline,
  TimeLinePost,
} from '../../constants/general.constant';
import { PaginatedResponse } from '../../models/paginated-response.model';
import {
  CommentsModel,
  LikeResponseModel,
  PostResponseModel,
} from '../../models/post.model';
import { BasePage } from '../../pages/base.page';
import { AuthService } from '../../services/auth.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { PostService } from '../../services/post.service';
import { UserService } from '../../services/user.service';
import { ReportAdComponent } from '../report-ad/report-ad.component';

@Component({
  selector: 'studinty-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent
  extends BasePage
  implements OnInit, AfterViewInit, OnDestroy
{
  commentsForm: UntypedFormGroup;
  requestInProgress = false;
  showShortDescription = false;

  adTypes = {
    housingAd: HousingAd,
    productAd: ProductAd,
    eventAd: EventAd,
    postAd: PostAd,
  };

  loadMoreComments = false; // check whether to display show more comments link or not
  redirectToDetail = false; // to redirect to timeline item detail page based on comments length

  @Input() type: string;
  @Input() post: PostResponseModel;
  @ViewChild('inputComment') inputRef: IonTextarea;
  @Output() postDeleted: EventEmitter<number> = new EventEmitter();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  slideOpts = {
    initialSlide: 1,
    speed: 400,
    centeredSlides: true,
    slidesPerView: 1.1,
    spaceBetween: 8,
    pagination: false,
    breakpoints: {
      640: {
        slidesPerView: 1.6,
      },
      768: {
        slidesPerView: 1.6,
      },
      1024: {
        slidesPerView: 1.6,
      },
      1199: {
        slidesPerView: 2.5,
      },
    },
  };

  constructor(
    private router: Router,
    protected platform: Platform,
    loadingCtrl: LoadingController,
    protected userService: UserService,
    protected postService: PostService,
    protected authService: AuthService,
    protected alertController: AlertController,
    protected translate: TranslateService,
    public modalController: ModalController,
    private errorHandler: ErrorHandlerService,
  ) {
    super(platform, userService, authService, translate, loadingCtrl);
    this.commentsForm = new UntypedFormGroup({
      comments: new UntypedFormControl([]),
      editCommentId: new UntypedFormControl(''),
    });
  }

  ngOnInit(): void {
    this.post.current_page =
      this.post.comments_count > 3
        ? this.pagination(this.post.comments_count)?.current_page + 1
        : 1;
  }

  ngAfterViewInit() {
    if (this.post.comments_count > 3) {
      this.loadMoreComments = true;
    }

    // modify post object if type is post to traverse images
    if (
      this.post.type === this.adTypes.postAd &&
      this.post.time_line.background_image
    ) {
      this.post.time_line.images = [];
      this.post.time_line.images.push(this.post.time_line.background_image);
    }

    this.post.comments = this.post.comments.reverse();
  }

  /**
   * it will handle lik case for post
   *
   * @param id post id
   * @returns void
   */
  likeAction(id: number): void {
    if (this.requestInProgress) {
      return;
    }
    this.requestInProgress = true;
    this.postService
      .likePost(id, this.post.type)
      .pipe(
        finalize(() => (this.requestInProgress = false)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response: LikeResponseModel) => {
          if (response.is_liked) {
            this.post.likes_count++;
          } else {
            this.post.likes_count--;
          }
          this.post.is_liked = response.is_liked;
        },
        error: (error: unknown) => this.errorHandler.handle(error),
      });
  }

  /**
   * To favourite Post
   *
   * @param postId number, id of post
   * @returns void
   */
  favouriteAction(post: PostResponseModel): void {
    this.showLoader().then(() => {
      this.postService
        .favPost(post.time_line.id, post.type)
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (res) => {
            post.is_favorite = !post.is_favorite;
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    });
  }

  /**
   * To do comment on a post
   *
   * @param postId number, post id
   * @returns void
   */
  postComment(postId: number): void {
    if (!this.commentsForm.controls.editCommentId.value) {
      const text = this.commentsForm.controls.comments.value;
      // if text is empty return from here. cant do empty comment
      if (text === '' || text === null || text === undefined) {
        return;
      }
      this.showLoader();
      const payload: CommentsModel = { text };
      this.postService
        .commentPost(postId, payload, this.post.type)
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (res: any) => {
            this.post.comments.push(res);
            this.commentsForm.reset();
            this.post.comments_count = this.post.comments_count + 1;
          },
          error: (error: unknown) => this.errorHandler.handle(error),
        });
    } else {
      const [commentId, comment] = [
        this.commentsForm.controls.editCommentId.value,
        this.commentsForm.controls.comments.value,
      ];

      if (comment === '' || comment === null || comment === undefined) {
        return;
      }

      this.showLoader();
      this.postService
        .updateComment(commentId, comment)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            this.hideLoader();
            this.commentsForm.controls.editCommentId.setValue('');
            this.commentsForm.reset();
            this.post.comments = this.post.comments.map((com) => {
              if (com.id === commentId) {
                com.text = comment;
              }
              return com;
            });
          },
          error: (error: unknown) => {
            this.hideLoader();
            this.errorHandler.handle(error);
          },
        });
    }
  }

  /**
   * @param itemId comment || post id to report
   */
  async presentReportCommentModal(itemId: number, type: string = 'comment') {
    const modal = await this.modalController.create({
      component: ReportAdComponent,
      componentProps: {
        adType: type,
        itemId,
      },
    });

    return await modal.present();
  }

  /**
   * Fetch comments
   *
   * @returns void
   */
  getComments(): void {
    this.showLoader().then(() => {
      this.postService
        .fetchAllComments(this.post.time_line.id, this.post.type, {
          page: this.post.current_page,
          per_page: 3,
          direction: desc,
        })
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (comments: PaginatedResponse<CommentsModel>) => {
            comments.data.map((c) => this.post.comments.unshift(c));
            const ids = this.post.comments.map((o) => o.id);
            this.post.comments = this.post.comments.filter(
              ({ id }, index) => !ids.includes(id, index + 1),
            );

            if (comments.meta.last_page <= comments.meta.current_page) {
              this.loadMoreComments = false;
            } else {
              this.post.current_page++;
              this.loadMoreComments = true;
              if (this.type === Timeline) {
                this.redirectToDetail = true;
              }
            }
          },
          error: (error: unknown) => {
            this.errorHandler.handle(error);
          },
        });
    });
  }

  showLess() {
    this.post.current_page = 1;
    this.post.comments = [];
    this.getComments();
  }

  routeToTimelineDetail(type: string, id: number): void {
    if (this.type === TimeLinePost) {
      return;
    }
    this.router.navigate(['tabs', 'timeline', 'timeline-detail'], {
      queryParams: { item_id: id, item_type: type },
    });
  }

  pagination(length, currentPage: number = 1, itemsPerPage: number = 3) {
    // calculate total pages
    const totalPages = Math.ceil(length / itemsPerPage);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let endPage: number;
    let startPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, itemsPerPage - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i,
    );

    return {
      total: length,
      per_page: itemsPerPage,
      current_page: currentPage,
      last_page: Math.ceil(length / itemsPerPage),
      from: (currentPage - 1) * itemsPerPage + 1,
      to: currentPage * itemsPerPage,
      offset: (currentPage - 1) * itemsPerPage + 1,
      startIndex,
      endIndex,
      pages,
    };
  }

  /**
   * Auto Focus comments field
   */
  async focusCommentField() {
    await this.inputRef.getInputElement().then((item) => {
      item.focus();
    });
  }

  /**
   * Add type based navigation
   *
   * @param type of type string, tells add type
   * @param id of type number, id of Add
   * @returns void
   */
  typeBasedNavigation(type: string, id: number): void {
    switch (type) {
      case Post: {
        if (this.router.url.includes('timeline-detail')) {
          console.log('returning, user is already on same page');
          return;
        }
        this.router.navigate(['tabs/timeline/timeline-detail'], {
          queryParams: { item_id: id, item_type: type },
        });
        break;
      }
      case HousingAd: {
        this.router.navigate(['housing-detail'], {
          queryParams: { item_id: id },
        });
        break;
      }
      case EventAd: {
        this.router.navigate(['event-detail'], {
          queryParams: { item_id: id },
        });
        break;
      }
      case ProductAd: {
        this.router.navigate(['product-detail'], {
          queryParams: { item_id: id },
        });
        break;
      }
      case 'profile': {
        this.router.navigate(['profile-detail'], {
          queryParams: { user_id: id, ref: 'tabs/timeline' },
        });
        break;
      }
      default: {
        console.log(
          'issue detected with routing type, Unable to find any match.',
        );
        break;
      }
    }
  }

  gotoCreatePost(formItem = null, openMode: string): void {
    this.router.navigate(
      [
        'add-post',
        {
          mode: openMode,
        },
      ],
      { state: { item: formItem } },
    );
  }

  async deleteConfirm(itemId: number) {
    const alert = await this.alertController.create({
      header: this.translate.instant(marker('RemovePost')),
      message: this.translate.instant(marker('RemovePostConfirm')),
      buttons: [
        {
          text: this.translate.instant(marker('Buttons.Cancel')),
        },
        {
          text: this.translate.instant('Buttons.Ok'),
          handler: () => {
            this.deletePost(itemId);
          },
        },
      ],
    });
    await alert.present();
  }

  deletePost(postId: number) {
    this.showLoader().then(() => {
      this.postService
        .deletePost(postId)
        .pipe(
          finalize(() => this.hideLoader()),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (resp) => {
            this.postDeleted.emit(postId);
          },
          error: (error: unknown) => {
            this.errorHandler.handle(error);
          },
        });
    });
  }

  checkPostType(type: string) {
    switch (type) {
      case PostAd: {
        return 'Added a post';
      }
      case HousingAd: {
        return 'Added a new room in Housing';
      }
      case EventAd: {
        return 'Created a new event';
      }
      case ProductAd: {
        return 'Listed a new item in Marketplace';
      }
      default: {
        break;
      }
    }
    return type;
  }

  async deleteCommentConfirm(commentId: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant(marker('RemoveComment')),
      message: this.translate.instant(marker('RemoveCommentConfirm')),
      buttons: [
        {
          text: this.translate.instant(marker('Buttons.Cancel')),
        },
        {
          text: this.translate.instant(marker('Buttons.Ok')),
          handler: () => this.deleteComment(commentId),
        },
      ],
    });
    await alert.present();
  }

  deleteComment(commentId: string) {
    this.postService
      .deleteComment(commentId)
      .pipe(
        finalize(() => this.hideLoader()),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (res) => {
          this.post.comments = this.post.comments.filter(
            (comment) => `${comment.id}` !== commentId,
          );
          this.post.current_page = this.pagination(
            this.post.comments?.length,
            this.post.current_page - 1,
          ).current_page;
        },
        error: (error: unknown) => {
          this.errorHandler.handle(error);
        },
      });
  }

  editComment(commentId: string, text: string) {
    this.commentsForm.controls.comments.setValue(text);
    this.commentsForm.controls.editCommentId.setValue(commentId);
  }

  cancelComment() {
    this.commentsForm.reset();
  }

  toggleDescription() {
    this.showShortDescription = !this.showShortDescription;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
