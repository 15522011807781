import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';

import { HttpService } from './http.service';

@Injectable()
export class ImageUploadService extends HttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Store a file in S3 and return its UUID, key, and other information.
   */
  async store(response, file, name: string, options: any) {
    const headers = response.headers;
    console.log('headers', headers);

    if ('Host' in headers) {
      delete headers.Host;
    }

    if (typeof options.progress === 'undefined') {
      options.progress = () => {};
    }

    const cancelToken = options.cancelToken || '';

    await axios.put(response.url, file, {
      cancelToken,
      headers,
      onUploadProgress: (progressEvent) => {
        options.progress(progressEvent.loaded / progressEvent.total);
      },
    });

    response.extension = name.split('.').pop();

    return response;
  }

  getSignedUrl(contentType: string) {
    return this.http.post(this.buildUrl('signed/url'), {
      content_type: contentType,
    });
  }

  deleteImage(deleteImages) {
    return this.http.request('delete', this.buildUrl('media/delete'), {
      body: deleteImages,
    });
  }

  deletePostImage(imageId: number) {
    return this.http.request(
      'delete',
      this.buildUrl(`media/delete/by/${imageId}/id`),
      {},
    );
  }
}
