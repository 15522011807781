import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';

import { AuthService } from './auth.service';
import { ChatService } from './chat.service';
import { FeedbackService } from './feedback.service';
import { GenralService } from './genral.service';
import { GoolgeSDKService } from './googleSDK.service';
import { ImageManagerService } from './image-manager.service';
import { ImageUploadService } from './image-upload.service';
import { NotificationService } from './notification.service';
import { PostService } from './post.service';
import { PushSubscriptionService } from './push-subscription.service';
import { PusherService } from './pusher.service';
import { RequestInterceptorService } from './request-interceptor.service';
import { TimeLineService } from './time-line.service';
import { TokenStoreService } from './token-store.service';
import { UserService } from './user.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    Diagnostic,
    UserService,
    AuthService,
    Geolocation,
    NativeGeocoder,
    PostService,
    ChatService,
    GenralService,
    TimeLineService,
    FeedbackService,
    GoolgeSDKService,
    TokenStoreService,
    AndroidPermissions,
    ImageManagerService,
    ImageUploadService,
    PushSubscriptionService,
    NotificationService,
    PusherService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
  ],
  exports: [HttpClientModule],
})
export class ServicesModule {}
