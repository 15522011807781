import { Injectable, NgZone } from '@angular/core';
import {
  Camera,
  MediaType,
  PictureSourceType,
} from '@awesome-cordova-plugins/camera/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import {
  MediaCapture,
  MediaFile,
} from '@awesome-cordova-plugins/media-capture/ngx';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionSheetController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class MediaCaptureProvider {
  constructor(
    private ngZone: NgZone,
    private actionSheetCtrl: ActionSheetController,
    private camera: Camera,
    private mediaCapture: MediaCapture,
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private webview: WebView,
  ) {}

  public getPicture(
    from: PictureSourceType,
    asDataUrl: true,
    type?: MediaType,
    sizeLimit?: number,
  ): Promise<string>;
  public getPicture(
    from: PictureSourceType,
    asDataUrl?: boolean,
    type?: MediaType,
    sizeLimit?: number,
  ): Promise<Blob>;
  public getPicture(
    from: PictureSourceType,
    asDataUrl: boolean = false,
    type: MediaType = this.camera.MediaType.PICTURE,
    sizeLimit: number = 20971520,
  ): Promise<Blob | string> {
    return new Promise<Blob | string>((resolve, reject) => {
      this.camera
        .getPicture({
          sourceType: from,
          // encodingType: this.camera.EncodingType.JPEG,
          destinationType: this.camera.DestinationType.FILE_URI,
          mediaType: type,
          quality: 50,
          correctOrientation: true,
        })
        .then(
          (result) => {
            if (result) {
              this.cordovaReadFile(result, asDataUrl, sizeLimit).then(
                (dataUrl) => {
                  // tslint:disable-next-line: no-string-literal
                  dataUrl['url'] = this.webview.convertFileSrc(dataUrl['url']);
                  resolve(dataUrl);
                },
                (error) => {
                  reject(error);
                },
              );
              return;
            }

            resolve(null);
          },
          (error) => {
            this.ngZone.run(() =>
              reject(this.translate.instant('ValidationMessage.ImageOnly')),
            );
          },
        );
    });
  }

  public getVideo(
    from: PictureSourceType,
    asDataUrl: true,
    sizeLimit?: number,
  ): Promise<string>;
  public getVideo(
    from: PictureSourceType,
    asDataUrl?: boolean,
    sizeLimit?: number,
  ): Promise<Blob>;
  public getVideo(
    from: PictureSourceType,
    asDataUrl: boolean = false,
    sizeLimit: number = 20971520,
  ): Promise<Blob | string> {
    if (from !== this.camera.PictureSourceType.CAMERA) {
      return this.getPicture(from, asDataUrl, this.camera.MediaType.VIDEO);
    }

    return new Promise<Blob>((resolve, reject) => {
      return this.mediaCapture.captureVideo().then(
        (capture: MediaFile[]) => {
          if (capture && capture.length) {
            this.cordovaReadFile(
              capture[0].fullPath,
              asDataUrl,
              sizeLimit,
            ).then(
              (result) => resolve(result),
              (reason) => reject(reason),
            );

            return;
          }

          resolve(null);
        },
        (error) => this.ngZone.run(() => reject(error)),
      );
    });
  }

  cordovaPictureVideo(asDataUrl: true, sizeLimit?: number): Promise<string>;
  cordovaPictureVideo(asDataUrl?: boolean, sizeLimit?: number): Promise<Blob>;
  cordovaPictureVideo(
    asDataUrl: boolean = false,
    sizeLimit: number = 20971520,
  ): Promise<Blob | string> {
    return new Promise<Blob | string>(async (resolve, reject) => {
      const alert = await this.actionSheetCtrl.create({
        header: this.translate.instant(marker('Camera.Select')),
        backdropDismiss: true,
        buttons: [
          {
            icon: 'close',
            text: this.translate.instant(marker('Buttons.Cancel')),
            role: 'cancel',
          },
          {
            icon: 'camera',
            text: this.translate.instant(marker('Camera.Source.Camera')),
            handler: () => {
              this.getPicture(
                this.camera.PictureSourceType.CAMERA,
                asDataUrl,
                this.camera.MediaType.PICTURE,
                sizeLimit,
              ).then(
                (result) => resolve(result),
                (error) => reject(error),
              );
            },
          },
          {
            icon: 'photos',
            text: this.translate.instant(marker('Camera.Source.PhotoLibrary')),
            handler: () => {
              this.getPicture(
                this.camera.PictureSourceType.PHOTOLIBRARY,
                asDataUrl,
                this.camera.MediaType.ALLMEDIA,
                sizeLimit,
              ).then(
                (result) => resolve(result),
                (error) => reject(error),
              );
            },
          },
        ],
      });
      await alert.present();
    });
  }

  private cordovaReadFile(
    fileUri: string,
    asDataUrl: true,
    sizeLimit?: number,
  ): Promise<string>;
  private cordovaReadFile(
    fileUri: string,
    asDataUrl?: boolean,
    sizeLimit?: number,
  ): Promise<Blob>;
  private cordovaReadFile(
    fileUri: string,
    asDataUrl: boolean = false,
    sizeLimit: number = 20971520,
  ): Promise<Blob | string | {}> {
    return new Promise((resolve, reject) => {
      let selectedFileUri;
      if (
        fileUri.indexOf('file://') !== 0 &&
        fileUri.indexOf('content://') !== 0
      ) {
        selectedFileUri = `file://${fileUri}`;
      } else {
        selectedFileUri = fileUri;
      }

      window.resolveLocalFileSystemURL(
        selectedFileUri,
        (fileEntry) => {
          fileEntry.file(
            (file) => {
              if (
                file.type.indexOf('image/') !== 0 ||
                file.type.indexOf('application/pdf') !== 0
              ) {
                if (file.size <= sizeLimit) {
                  const reader = new window.FileReader();

                  if (asDataUrl) {
                    reader.onloadend = () => {
                      resolve({
                        file: reader.result,
                        url: selectedFileUri,
                        fileName: file.name,
                      });
                    };

                    reader.readAsDataURL(file);
                  } else {
                    const fileResult = new Blob([reader.result as ArrayBuffer]);
                    (fileResult as any).name = file.name;

                    reader.onloadend = () => {
                      resolve({
                        file: fileResult,
                        url: selectedFileUri,
                        fileName: file.name,
                      });
                    };

                    reader.readAsArrayBuffer(file);
                  }
                } else {
                  reject(
                    this.translate.instant('MediaCapture.SizeLimit', {
                      size: sizeLimit / 1024 / 1024,
                    }),
                  );
                }
              } else {
                reject(this.translate.instant('MediaCapture.TypeLimit'));
              }
            },
            (fileEntryError) => {
              reject(JSON.stringify(fileEntryError));
            },
          );
        },
        (resolveFSError) => {
          reject(JSON.stringify(resolveFSError));
        },
      );
    });
  }

  readFile(file: any, sizeLimit?: number): Promise<string> {
    if (!sizeLimit) {
      sizeLimit = 20971520;
    }

    if (file && file.size >= sizeLimit) {
      return new Promise((resolve, reject) => {
        const msg = this.translate.instant(marker('FileSizeLimit'));
        reject(msg + `${sizeLimit / 1024 / 1024} MB's`);
      });
    }

    if (typeof file === 'string') {
      if (!/^data:/.test(file)) {
        return this.cordovaReadFile(file, true, sizeLimit);
      }

      return Promise.resolve(file);
    }

    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();

      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.abort = () => {
        reject('aborted');
      };
      reader.reject = (event: ErrorEvent) => {
        reject(event.error);
      };
      reader.readAsDataURL(file);
    });
  }
}
