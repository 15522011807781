import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  private value: string | null;
  private lastDate: any;
  private lastLang: string;

  constructor(private translateService: TranslateService) {}

  transform(date: any, pattern: string = 'mediumDate'): any {
    const currentLang = this.translateService.currentLang;
    const daysEN = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const daysNL = [
      'Zondag',
      'Maandag',
      'Dinsdag',
      'Woensdag',
      'Donderdag',
      'Vrijdag',
      'Zaterdag',
    ];
    const weekDay = currentLang === 'en' ? daysEN : daysNL;
    // if we ask another time for the same date & locale, return the last value
    if (date === this.lastDate && currentLang === this.lastLang) {
      return this.value;
    }

    this.value = new DatePipe(currentLang).transform(date, pattern);
    const index = moment(this.value).day();
    this.value = weekDay[index] + ', ' + this.value;
    this.lastDate = date;
    this.lastLang = currentLang;

    return this.value;
  }
}
