import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'studinty-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss'],
})
export class ValidationMessagesComponent {
  @Input() private control: AbstractControl;
  @Input() relatedControls: string[];
  @Input() label: string;
  @Input() secondLabel: string;
  @Input() labels: any;
  @Input() pattern: string;
  @Input() customMessage: string;

  constructor(private cd: ChangeDetectorRef) {}

  get touched() {
    if (this.control) {
      if (
        this.control instanceof UntypedFormGroup &&
        this.relatedControls &&
        this.relatedControls.length
      ) {
        return this.relatedControls.reduce((previous, current) => {
          if (previous) {
            const control = this.control.get(current);
            if (control && control.touched) {
              return true;
            }
          }

          return false;
        }, true);
      }

      return this.control.touched;
    }

    return false;
  }

  get visible() {
    return this.touched && this.control.invalid && this.error;
  }

  get error() {
    if (this.control && this.control.errors) {
      const keys = Object.keys(this.control.errors);

      if (keys.length) {
        return keys[0];
      }
    }

    return null;
  }

  getLabel(key: string = this.error, ...subKeys: string[]) {
    if (this.labels && typeof this.labels === 'object') {
      const labelKey = this.getValue(key, ...subKeys);

      if (
        (typeof labelKey === 'string' || typeof labelKey === 'number') &&
        this.labels[labelKey]
      ) {
        return this.labels[labelKey];
      }
    }

    return null;
  }

  getValue(key: string = this.error, ...subKeys: string[]) {
    if (this.control && this.control.errors) {
      if (this.control.errors[key]) {
        let value = this.control.errors[key];

        if (subKeys.length && typeof value === 'object') {
          let i = 0;

          do {
            value = value[subKeys[i]];
            if (!value) {
              return null;
            }

            i++;
          } while (i < subKeys.length);
        }

        return value;
      }
    }

    return null;
  }
}
