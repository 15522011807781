import { HttpClient, HttpParams } from '@angular/common/http';

import { Config } from '../config';

export abstract class HttpService {
  protected constructor(protected readonly http: HttpClient) {}

  protected buildUrl(...parts: Array<string | number>) {
    return `${Config.baseUrl}/${parts.map((item) => `${item}`).join('/')}`;
  }

  protected serialize(
    params: any,
    response?: HttpParams,
    key?: string,
  ): HttpParams {
    response = response || new HttpParams();

    if (typeof params === 'undefined' || (!key && params === null)) {
      return response;
    }

    if ((Array.isArray(params) || typeof params !== 'object') && !key) {
      throw new Error('Params must be an object');
    }

    if (params === null) {
      return response.append(key, '');
    }

    if (typeof params === 'boolean') {
      return response.append(key, params ? '1' : '0');
    }

    if (params instanceof Date) {
      return response.append(key, params.toISOString());
    }

    if (Array.isArray(params)) {
      return params.reduce(
        (result, item, index) =>
          this.serialize(item, result, `${key}[${index}]`),
        response,
      );
    }

    if (typeof params === 'object') {
      return Object.keys(params).reduce((result, child) => {
        if (key) {
          return this.serialize(params[child], result, `${key}[${child}]`);
        }

        return this.serialize(params[child], result, child);
      }, response);
    }

    return response.append(key, `${params}`);
  }
}
